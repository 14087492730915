import { Typography } from '@mui/material'
import { getWeek } from 'date-fns'
import { CustomTickProps } from '~pages/pages-behind-login/chartering/types/exposure-types'

export const CustomWeekTick = ({ payload, ...rest }: CustomTickProps) => {
  const value = payload?.value ? getWeek(new Date(payload?.value)) : ''
  return (
    // @ts-ignore
    <Typography
      component="text" // SVG element
      {...rest}
      textAnchor="middle"
      variant="graphCaption"
    >
      {value}
    </Typography>
  )
}
