import { Loader, NavigationButton } from '@maersktankersdigital/web-components'
import { memo, useEffect, useMemo, useState } from 'react'
import ErrorDisplay from '~components/layout/error/error-display/error-display'
import VoyageTracker from '~components/molecules/voyage-tracker'
import { VoyageNavigator } from '~components/molecules/voyage-tracker/voyage-tracker.utils'
import VoyageTrackerHeader from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/voyage-tracker-position-list/voyage-tracker-header'
import {
  VoyageNavigationWrapper,
  VoyageTrackerWrapper,
  Wrapper,
} from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/voyage-tracker-position-list/voyage-tracker-position-list.styles'
import { PositionListVoyage } from '~pages/pages-behind-login/chartering/types/position-list-types'

interface Props {
  voyages: PositionListVoyage[]
}

function VoyageTrackerPositionlist({ voyages }: Props) {
  const voyageNavigator = useMemo(() => new VoyageNavigator(voyages), [voyages])
  const [currentVoyage, setCurrentVoyage] = useState(
    voyageNavigator.getSelectedVoyage(),
  )

  useEffect(() => {
    setCurrentVoyage(voyageNavigator.getSelectedVoyage())
  }, [voyageNavigator])

  const itineraryData = currentVoyage?.itineraries
  const hasItineraryData = itineraryData && itineraryData.length > 0

  const handleNextClick = () => {
    const nextVoyage = voyageNavigator.getNextVoyage()
    if (nextVoyage) {
      setCurrentVoyage(nextVoyage)
    }
  }

  const handlePrevClick = () => {
    const prevVoyage = voyageNavigator.getPreviousVoyage()
    if (prevVoyage) {
      setCurrentVoyage(prevVoyage)
    }
  }

  return (
    <Wrapper>
      {voyages?.length > 0 ? (
        currentVoyage ? (
          <>
            <VoyageTrackerHeader
              currentVoyage={currentVoyage as PositionListVoyage}
            />
            <VoyageTrackerWrapper>
              {hasItineraryData && <VoyageTracker voyageData={currentVoyage} />}
            </VoyageTrackerWrapper>
            <VoyageNavigationWrapper>
              <NavButtons
                onPrevClick={handlePrevClick}
                onNextClick={handleNextClick}
                isPrevDisabled={!voyageNavigator.hasPreviousVoyage()}
                isNextDisabled={!voyageNavigator.hasNextVoyage()}
              />
            </VoyageNavigationWrapper>
          </>
        ) : (
          <ErrorDisplay text="Sorry, we couldn't find any current voyage data." />
        )
      ) : (
        <Loader />
      )}
    </Wrapper>
  )
}

export default memo(VoyageTrackerPositionlist)

export function NavButtons({
  onPrevClick,
  onNextClick,
  isPrevDisabled,
  isNextDisabled,
}: {
  onPrevClick: () => void
  onNextClick: () => void
  isPrevDisabled: boolean
  isNextDisabled: boolean
}) {
  return (
    <>
      <NavigationButton
        variant="left"
        ariaLabel="Previous voyage"
        onClick={onPrevClick}
        text="Previous voyage"
        disabled={isPrevDisabled}
      />
      <NavigationButton
        variant="right"
        ariaLabel="Next voyage"
        text="Next voyage"
        onClick={onNextClick}
        disabled={isNextDisabled}
      />
    </>
  )
}
