import { TableBody, TableCell, TableRow } from '@mui/material'

export function SingleCellTableBody({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={16} sx={{ p: 0 }}>
          {children}
        </TableCell>
      </TableRow>
    </TableBody>
  )
}
