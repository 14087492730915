import { Typography } from '@mui/material'
import { Tooltip } from '~components/tooltip'

export function ArrayPreviewWithTooltip({ array }: { array?: string[] }) {
  return !array?.length ? (
    <></>
  ) : array?.length === 1 ? (
    <Typography variant="caption">{array[0]}</Typography>
  ) : (
    <Tooltip
      title={
        <>
          {array.map((area, index) => (
            <Typography key={index} variant="caption" component="p">
              {area}
            </Typography>
          ))}
        </>
      }
    >
      <>
        <Typography variant="caption">{array[0]}</Typography>
        <Typography variant="label">{` + ${array?.length - 1}`}</Typography>
      </>
    </Tooltip>
  )
}
