import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'

import { typography } from '../global/typography'
import { checkboxHoverStyle } from './checkbox'

export interface MuiFormControlLabelData {
  defaultProps?: ComponentsProps['MuiFormControlLabel']
  styleOverrides?: ComponentsOverrides['MuiFormControlLabel']
  variants?: ComponentsVariants['MuiFormControlLabel']
}

export const MuiFormControlLabel: MuiFormControlLabelData = {
  styleOverrides: {
    root: {
      '&:hover': {
        '& .MuiButtonBase-root:not(.Mui-checked):not(.Mui-disabled):not(.MuiRadio-root)':
          {
            ...checkboxHoverStyle,
          },
      },

      '& .MuiFormControlLabel-label': { ...typography.paragraph2 },
    },
  },
}
