export const graphqlBase = () => {
  const { location } = window
  return import.meta.env.VITE_GRAPHQL_URL
    ? `${import.meta.env.VITE_GRAPHQL_URL}`
    : `${location.protocol}//graphql.${location.host}`
}

export const apiBase = () =>
  import.meta.env.VITE_API_URL ? `${import.meta.env.VITE_API_URL}/api` : '/api'

export const webSocketBase = () => {
  const getHostBase = () => {
    const { location } = window
    if (
      location.hostname.includes('test') ||
      location.hostname.includes('localhost')
    ) {
      return 'maersktankers-test.digital'
    }

    if (location.hostname.includes('stage')) {
      return 'maersktankers-stage.digital'
    }

    return 'maersktankers.digital'
  }

  return import.meta.env.VITE_WS_URL
    ? `${import.meta.env.VITE_WS_URL}`
    : `wss://websockets.${getHostBase()}`
}
