import {
  cargoGradeOptions,
  productCodesWithCargoGrades,
} from '../../../content/select-n-autocomplete-options'
import { FieldsGroupProps } from '../../shared-fields'
import { MultiItemAutocomplete } from './base-components/multi-item-autocomplete'

export function CargoGradeAutocomplete({
  formInput,
  updateFormInput,
  missingFields,
  disabledFields,
}: FieldsGroupProps) {
  return (
    <MultiItemAutocomplete
      label="Cargo Grade*"
      input={formInput.cargoGrade || []}
      onChangeHandler={(e, newValues) => {
        if (!newValues) return
        updateFormInput({ cargoGrade: newValues })
      }}
      options={[...cargoGradeOptions, ...productCodesWithCargoGrades]}
      hasError={missingFields?.includes('cargoGrade')}
      disabled={disabledFields?.includes('cargoGrade')}
    />
  )
}
