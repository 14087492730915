import { useAuth0 } from '@auth0/auth0-react'
import Nes from '@hapi/nes/lib/client'
import { useEffect } from 'react'
import { queryClient } from 'src'
import { tenMinutes } from '~constants/constants'
import { PatchCargoPayload } from '~hooks/queries/cargo/use-post-patch-delete-cargo'
import { webSocketBase } from '~utils/base-url'
import { GetCargoItem } from './use-get-cargo'

interface Change {
  type: string
  fullDocument: GetCargoItem
  documentKey: { _id: string }
  updatedFields: PatchCargoPayload
}

export function useCargoSubscription() {
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    const cargoClient = new Nes.Client(
      webSocketBase() as `ws://${string}` | `wss://${string}`,
    )

    const startWebSocket = async () => {
      try {
        const token = await getAccessTokenSilently()

        await cargoClient.connect({
          auth: { headers: { Authorization: token } },
        })

        cargoClient.subscribe('/cargo', (change: unknown) => {
          const data = change as Change

          if (data.type === 'insert' && data.fullDocument) {
            queryClient.setQueryData<GetCargoItem[]>(['/cargo'], (oldData) => {
              return [data.fullDocument, ...(oldData || [])]
            })
          }
          if (data.type === 'delete' && data.documentKey) {
            queryClient.setQueryData<GetCargoItem[]>(['/cargo'], (oldData) => {
              return oldData?.filter(
                (cargo) => cargo._id !== data.documentKey._id,
              )
            })
          }
          if (
            data.type === 'update' &&
            data.documentKey &&
            data.updatedFields
          ) {
            queryClient.setQueryData<GetCargoItem[]>(['/cargo'], (oldData) => {
              const updatedItem = oldData?.find(
                (item) => item._id === data.documentKey._id,
              )

              return oldData?.map((item) =>
                item._id === data.documentKey._id
                  ? { ...updatedItem, ...data.updatedFields }
                  : item,
              ) as GetCargoItem[]
            })
          }
        })
      } catch (error) {
        console.error('WebSocket connection error:', error)
      }
    }

    startWebSocket()
    const intervalId = setInterval(() => {
      queryClient.invalidateQueries({ queryKey: ['/cargo'] })
    }, tenMinutes)

    return () => {
      clearInterval(intervalId)
      cargoClient.disconnect()
      console.log('WebSocket disconnected')
    }
  }, [getAccessTokenSilently])
}
