import {
  Button,
  ClickPoint,
  DatePicker,
  Input,
  Select,
  TextArea,
  theme,
  typographyStyles,
} from '@maersktankersdigital/web-components'
import { Modal as BaseModal } from '@mui/base'
import styled from 'styled-components/macro'
import { COLORS } from '~theme/colors'
import { Z_INDEX } from '~types/z-index'

const { SPACINGS, BREAKPOINTS } = theme

export const ModalHeader = styled.div`
  margin-bottom: ${SPACINGS[3]};
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const ModalTitle = styled.h2`
  ${typographyStyles.paragraphBoldLarge};
  margin: 0;
`

export const ModalBody = styled.div``

export const StyledInput = styled(Input)`
  width: 300px;

  @media only screen and (${BREAKPOINTS.sm}) {
    width: 344px;
  }
`

export const StyledSelect = styled(Select)`
  width: 300px;

  @media only screen and (${BREAKPOINTS.sm}) {
    width: 344px;
  }
`

export const StyledTextArea = styled(TextArea)`
  width: 300px;

  @media only screen and (${BREAKPOINTS.sm}) {
    width: 344px;
  }
`

export const StyledDatePicker = styled(DatePicker)`
  width: 300px;

  @media only screen and (${BREAKPOINTS.sm}) {
    width: 344px;
  }
`

export const ModalContent = styled.div`
  padding: ${SPACINGS[4]} ${SPACINGS[3]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 85vh;
  position: relative;
  background-color: ${COLORS.primary.white};
  width: 348px;
  box-sizing: border-box;

  @media only screen and (${BREAKPOINTS.sm}) {
    padding: ${SPACINGS[7]} ${SPACINGS[6]} ${SPACINGS[4]};
    width: 624px;
  }
`

export const StyledBackdrop = styled.div`
  z-index: -1;
  position: fixed;
  inset: 0;
  opacity: 0.75;
  background: ${COLORS.secondary.darkBlue};
  -webkit-tap-highlight-color: transparent;
`

export const StyledModal = styled(BaseModal)`
  position: fixed;
  z-index: ${Z_INDEX.Modal};
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const StyledWideModal = styled(StyledModal)`
  & ${ModalContent} {
    @media only screen and (${BREAKPOINTS.sm}) {
      width: 70vw;
    }
  }
`

export const StyledSecondaryModal = styled(StyledModal)`
  & ${ModalHeader} h2 {
    ${typographyStyles.paragraphBold2}
    text-transform: uppercase;
    margin: 0;
  }

  & ${ModalContent} {
    padding: ${SPACINGS[4]} ${SPACINGS[5]} ${SPACINGS[3]} ${SPACINGS[10]};
    background-color: ${COLORS.greys.ultraLight};
  }
`

export const StyledClickPoint = styled(ClickPoint)`
  position: absolute;
  top: 8px;
  right: 12px;

  @media only screen and (${BREAKPOINTS.sm}) {
    top: 16px;
    right: 24px;
  }
`

export const ModalFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${SPACINGS[4]};
  row-gap: ${SPACINGS[1]};
  margin-top: ${SPACINGS[5]};
  flex-direction: column-reverse;
  align-items: center;

  button {
    min-width: fit-content;
  }

  @media only screen and (${BREAKPOINTS.sm}) {
    justify-content: flex-end;
    flex-direction: row;
  }
`

export const PrimaryButton = styled(Button)`
  width: 200px;
`
export const StyledConfirmModal = styled(StyledModal)`
  & ${ModalHeader} h2 {
    ${typographyStyles.headline3}
  }

  & ${ModalContent} {
    padding: ${SPACINGS[3]} ${SPACINGS[7]};
    max-width: 360px;
    text-align: center;
    gap: 0;
  }

  & ${ModalFooter} {
    margin-top: ${SPACINGS[3]};
    justify-content: center;
  }

  & ${ModalBody} {
    justify-content: flex-start;
  }
`
