import { useQuery } from '@tanstack/react-query'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'

export function useGetCommercialOperator({
  disabled,
  commercialOperatorName,
  vesselName,
}: {
  disabled?: boolean
  commercialOperatorName?: string
  vesselName?: string
}) {
  const fetch = useFetch<string[]>()
  let url = '/cargo/commercialoperator'

  // the query parameters:  “vesselName" and “commercialOperatorName" are in an oxor relationship, meaning not both in the same call
  if (commercialOperatorName) {
    url = `${url}?commercialOperatorName=${commercialOperatorName}`
  } else if (vesselName) {
    url = `${url}?vesselName=${vesselName}`
  }

  return useQuery({
    queryKey: [url],
    queryFn: () => fetch(`${apiBase()}${url}`),
    enabled: !disabled,
  })
}
