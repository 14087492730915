import { useMatches } from 'react-router-dom'

export function useNavAndFooterConstants() {
  const matches = useMatches()
  // @ts-ignore
  const routeWithHamburgerMenu = matches.some((x) => x.handle?.withHamburger)

  const routeWithoutLeftNav = matches.some(
    // @ts-ignore
    (x) => x.handle?.disableLeftNavigation,
  )

  return {
    footerHeight: { xs: '168px', lg: '72px' },
    topNavigationHeight: { xs: '52px', xl: '72px' },
    leftNavigationWidth: { xs: 0, md: routeWithoutLeftNav ? 0 : '208px' },
  }
}
