import {
  ExportIcon,
  IconWrapper,
  VesselFrontIcon,
} from '@maersktankersdigital/web-components'
import { Box, Checkbox, Divider, FormControlLabel } from '@mui/material'
import * as React from 'react'
import { forwardRef, useEffect, useState } from 'react'
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard'

import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import { Modal } from '~components/molecules/modal/modal'
import { Tooltip } from '~components/tooltip'
import { useFilteredData } from '~pages/pages-behind-login/chartering/contexts/filtered-data-provider'
import { usePositionListView } from '~pages/pages-behind-login/chartering/contexts/position-list-view-provider'
import { useAreaFilteredVessels } from '~pages/pages-behind-login/chartering/hooks/use-area-filtered-vessels'
import {
  CheckboxWrapper,
  ExportButton,
  ExportModalContent,
  ExportVesselsWrapper,
  InnerMultiWrapper,
  MultiSelectWrapper,
  OverflowWrapper,
  PlusNText,
  StyledMultiButton,
  StyledMultiSelect,
} from '~pages/pages-behind-login/chartering/position-list-page/modals/export-modal/export-modal.styles'
import { generateHTMLTable } from '~pages/pages-behind-login/chartering/position-list-page/modals/export-modal/export-modal.utils'
import {
  loadAreaOptions,
  vesselSpecsOptions,
  worldAreaOptions,
} from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/position-list-table-controls/position-list-table-controls'

interface Props {
  setShowExportModal: (open: boolean) => void
  showExportModal: boolean
}
export interface MultiSelectValues {
  eta: string[]
  selectedVessels: string[]
  vesselSpecs: string[]
  worldArea: string[]
}

export interface CheckboxValues {
  lastThreeCargos: boolean
  openDate: boolean
  openPort: boolean
  intermediateCitySegments: boolean
  positionListStatus: boolean
  comments: boolean
}

const defaultMultiSelectValues: MultiSelectValues = {
  vesselSpecs: [],
  eta: [],
  selectedVessels: [],
  worldArea: [],
}

const defaultCheckboxValues: CheckboxValues = {
  openPort: true,
  openDate: true,
  lastThreeCargos: true,
  intermediateCitySegments: false,
  positionListStatus: false,
  comments: false,
}

function ExportListModal({ setShowExportModal, showExportModal }: Props) {
  const handleCloseModal = () => setShowExportModal(false)
  const { settings, activeView, saveSettings } = usePositionListView()
  const { filteredData } = useFilteredData()
  const { areaFilteredVessels } = useAreaFilteredVessels(
    filteredData,
    activeView?.filterSettings,
  )
  const [, copy] = useCopyToClipboard()
  const filteredExportedVessels = filteredData?.filter((vessel) =>
    settings?.exportedVessels?.includes(vessel.vesselId),
  )

  const { setStatus } = useAnimatedAlert()

  const [multiSelectValues, setMultiSelectValues] = useState<MultiSelectValues>(
    defaultMultiSelectValues,
  )

  const [checkboxValues, setCheckboxValues] = useState<CheckboxValues>(
    defaultCheckboxValues,
  )

  useEffect(() => {
    setMultiSelectValues((prevValues) => ({
      ...prevValues,
      vesselSpecs: vesselSpecsOptions
        .filter((spec) => activeView?.columns?.includes(spec.value))
        .map((spec) => spec.value),
    }))
    setMultiSelectValues((prevValues) => ({
      ...prevValues,
      eta: loadAreaOptions
        .filter((spec) => activeView?.columns?.includes(spec.value))
        .map((spec) => spec.value),
    }))
    setMultiSelectValues((prevValues) => ({
      ...prevValues,
      worldArea: worldAreaOptions
        .filter((spec) => activeView?.columns?.includes(spec.value))
        .map((spec) => spec.value),
    }))
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      lastThreeCargos:
        activeView?.columns?.includes('lastThreeCargos') || false,
      openDate: activeView?.columns?.includes('openDate') || false,
      openPort: activeView?.columns?.includes('openPort') || false,
    }))
  }, [activeView?.columns])

  useEffect(() => {
    setMultiSelectValues((prevValues) => ({
      ...prevValues,
      selectedVessels:
        filteredData
          ?.filter((vessel) =>
            settings?.exportedVessels?.includes(vessel.vesselId),
          )
          ?.map((vessel) => vessel.vessel) || [],
    }))
  }, [filteredData, settings?.exportedVessels])

  if (!showExportModal) return null

  const handleExport = () => {
    const sanitizedCheckboxValues = {
      ...checkboxValues,
      intermediateCitySegments: false,
    }

    const html =
      activeView?.showHeaders &&
      !activeView?.filterSettings?.worldArea?.includes('ALL')
        ? generateHTMLTable(
            areaFilteredVessels,
            multiSelectValues,
            sanitizedCheckboxValues,
            activeView?.columns || [],
          )
        : generateHTMLTable(
            [{ vessels: filteredData || [], name: '', children: [] }],
            multiSelectValues,
            sanitizedCheckboxValues,
            activeView?.columns || [],
          )

    saveSettings({ isExporting: false, exportedVessels: [] })
    setMultiSelectValues(defaultMultiSelectValues)
    setCheckboxValues(defaultCheckboxValues)
    setShowExportModal(false)
    copy(html, true, () => setStatus('Copied to clipboard!'))
  }

  const vesselNameOptions =
    filteredExportedVessels?.map((vessel) => ({
      label: vessel.vessel,
      value: vessel.vessel,
    })) || []

  const handleMultiSelectChange = (
    event:
      | React.KeyboardEvent<Element>
      | React.MouseEvent<Element, MouseEvent>
      | React.FocusEvent<Element>
      | null,
    selectedOptions: string[] | null,
    name: string,
  ) => {
    if (!selectedOptions) return
    let newFilterValue: string[] = []
    const allIndex = selectedOptions.indexOf('ALL')
    if (selectedOptions.length === 0) {
      newFilterValue = []
    } else if (allIndex > -1 && allIndex === selectedOptions.length - 1) {
      newFilterValue = ['ALL']
    } else {
      newFilterValue = selectedOptions.filter((val) => val !== 'ALL')
    }

    setMultiSelectValues((prevValues) => ({
      ...prevValues,
      [name]: newFilterValue,
    }))
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.checked,
    }))
  }

  function handleSegmentsCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    const isChecked = event.target.checked

    setCheckboxValues((prevValues) => ({
      ...prevValues,
      positionListStatus: isChecked,
      comments: isChecked,
      intermediateCitySegments: isChecked,
      openPort: isChecked,
      openDate: isChecked,
      lastThreeCargos: isChecked ? false : prevValues.lastThreeCargos,
    }))

    const preselectedVesselSpecs = ['yearBuilt', 'dwt', 'cbm', 'iceClass']

    function getSelectedSpecs() {
      const multiSelectValuesWithoutPreselectedSpecs =
        multiSelectValues.vesselSpecs.filter(
          (spec) => !preselectedVesselSpecs.includes(spec),
        )

      if (!isChecked) {
        return multiSelectValuesWithoutPreselectedSpecs
      }

      return [
        ...multiSelectValuesWithoutPreselectedSpecs,
        ...preselectedVesselSpecs,
      ]
    }

    handleMultiSelectChange(null, getSelectedSpecs(), 'vesselSpecs')
  }

  return (
    <Box
      component={Modal}
      sx={{
        '& > div:nth-of-type(3)': {
          width: '500px',
        },
      }}
      open={showExportModal}
      onClose={handleCloseModal}
    >
      <ExportModalContent>
        <ExportVesselsWrapper>
          <StyledMultiSelect
            label="Vessels"
            name="selectedVessels"
            value={multiSelectValues.selectedVessels}
            options={vesselNameOptions}
            onChange={handleMultiSelectChange}
            slotComponents={{ root: MultiButton }}
            slotProps={{
              root: {
                selectedOptions: multiSelectValues.selectedVessels,
                style: { width: '400px' },
              },
            }}
          />
        </ExportVesselsWrapper>
        <CheckboxWrapper>
          <FormControlLabel
            control={
              <Checkbox
                name="openDate"
                checked={checkboxValues.openDate}
                onChange={handleCheckboxChange}
              />
            }
            label="Open date"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="openPort"
                checked={checkboxValues.openPort}
                onChange={handleCheckboxChange}
              />
            }
            label="Open port"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="lastThreeCargos"
                checked={checkboxValues.lastThreeCargos}
                onChange={handleCheckboxChange}
              />
            }
            label="Last three cargos"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="positionListStatus"
                checked={checkboxValues.positionListStatus}
                onChange={handleCheckboxChange}
              />
            }
            label="Status"
          />

          <FormControlLabel
            control={
              <Checkbox
                name="comments"
                checked={checkboxValues.comments}
                onChange={handleCheckboxChange}
              />
            }
            label="Comments"
          />
        </CheckboxWrapper>
        <MultiSelectWrapper>
          <StyledMultiSelect
            label="Vessel specs"
            name="vesselSpecs"
            options={vesselSpecsOptions}
            value={multiSelectValues.vesselSpecs}
            onChange={handleMultiSelectChange}
          />
          <StyledMultiSelect
            label="ETA"
            name="eta"
            options={loadAreaOptions}
            value={multiSelectValues.eta}
            onChange={handleMultiSelectChange}
          />
        </MultiSelectWrapper>
        <MultiSelectWrapper>
          <StyledMultiSelect
            label="World Area"
            name="worldArea"
            options={worldAreaOptions}
            value={multiSelectValues.worldArea}
            onChange={handleMultiSelectChange}
          />
        </MultiSelectWrapper>
        <Box>
          <Divider sx={{ mb: 2 }} />
          <FormControlLabel
            control={
              <Checkbox
                name="intermediateCitySegments"
                checked={checkboxValues.intermediateCitySegments}
                onChange={handleSegmentsCheckboxChange}
              />
            }
            label={
              <Box
                component="span"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2,
                }}
              >
                Select Intermediate + City options
                <Tooltip
                  title={<ToolTipContent />}
                  slotProps={{ popper: { style: { zIndex: 9999 } } }}
                />
              </Box>
            }
          />
        </Box>
        <ExportButton
          variant="primary"
          icon={<ExportIcon width="16px" height="16px" />}
          onClick={handleExport}
        >
          Export
        </ExportButton>
      </ExportModalContent>
    </Box>
  )
}

function ToolTipContent() {
  return (
    <>
      <p>Selects:</p>
      <p>Vessel name</p>
      <p>Year build</p>
      <p>Dwt</p>
      <p>Cbm</p>
      <p>Ice class</p>
      <p>Open port</p>
      <p>Open date</p>
      <p>Status</p>
      <p>Comments</p>
    </>
  )
}

const MultiButton = forwardRef(function MultiButton(
  {
    selectedOptions,
    ...props
  }: {
    selectedOptions: string[]
  },
  ref,
) {
  return (
    <StyledMultiButton {...props} ref={ref}>
      <InnerMultiWrapper>
        <IconWrapper>
          <VesselFrontIcon size={16} />
        </IconWrapper>
        <OverflowWrapper>
          {selectedOptions.slice(0, 3).join(', ')}
          {selectedOptions.length > 3 && (
            <PlusNText>{` + ${selectedOptions.length - 3}`}</PlusNText>
          )}
        </OverflowWrapper>
      </InnerMultiWrapper>
    </StyledMultiButton>
  )
})

export default ExportListModal
