import {
  CheckIcon,
  CorrectionIcon,
  EyeIcon,
  InfoCircleIcon,
  LeafIcon,
  UnionIcon,
  WarningIcon,
} from '@maersktankersdigital/web-components'
import { Tooltip as MuiToolTip, TooltipProps } from '@mui/material'
import { COLORS } from '~theme/colors'

export type IconOptions =
  | 'warning'
  | 'denied'
  | 'confirm'
  | 'info'
  | 'eye'
  | 'correction'
  | 'fuel-eu'

export function Tooltip(props: {
  title: string | React.ReactNode
  placement?: TooltipProps['placement']
  icon?: IconOptions
  labelText?: string | React.ReactNode
  size?: number
  color?: string
  style?: React.CSSProperties
  slotProps?: TooltipProps['slotProps']
  children?: React.ReactNode
}) {
  const size = props.size || 16

  return (
    <MuiToolTip
      enterTouchDelay={0}
      placement={props.placement || 'bottom'}
      {...props}
    >
      <p
        style={{
          color: COLORS.secondary.midBlue,
          fontSize: 12,
          marginTop: !!props.labelText ? '12px' : 0,
          lineHeight: '16px',
          cursor: 'pointer',
          ...props.style,
        }}
      >
        {props.labelText ??
          (props.icon === 'denied' ? (
            <UnionIcon size={size - 1} color={props.color || COLORS.red.dark} />
          ) : props.icon === 'confirm' ? (
            <CheckIcon
              size={size}
              color={props.color || COLORS.secondary.midBlue}
            />
          ) : props.icon === 'warning' ? (
            <WarningIcon
              size={size}
              color={props.color || COLORS.secondary.darkBlue}
            />
          ) : props.icon === 'eye' ? (
            <EyeIcon size={size} color={props.color || COLORS.primary.white} />
          ) : props.icon === 'correction' ? (
            <CorrectionIcon
              size={size}
              color={props.color || COLORS.secondary.darkBlue}
            />
          ) : props.icon === 'fuel-eu' ? (
            <LeafIcon size={size} />
          ) : !props.children ? (
            <InfoCircleIcon
              size={size}
              color={props.color || COLORS.secondary.darkBlue}
            />
          ) : (
            props.children
          ))}
      </p>
    </MuiToolTip>
  )
}
