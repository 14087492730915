import {
  DatePicker,
  PlusIcon,
  Switch,
  TextButton,
} from '@maersktankersdigital/web-components'
import { Box, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { format } from 'date-fns'
import { useSearchParams } from 'react-router-dom'
import { Tooltip } from '~components/tooltip'
import { useGetMe } from '~hooks/queries/me/use-get-me'
import {
  CargoListPageSearchParams,
  dateFormat,
  defaultFilters,
} from '../constants'
import {
  cargoGradeOptions,
  productCodesWithCargoGrades,
} from '../content/select-n-autocomplete-options'
import { Type } from './cargo-modal/add-cargo-modal'
import { AreaRenderer, MultiItemAutocomplete } from './fields'

export interface ICargoFilters {
  [CargoListPageSearchParams.cargoGrade]?: string[]
  [CargoListPageSearchParams.pools]?: string[]
  [CargoListPageSearchParams.worldArea]?: string[]
  [CargoListPageSearchParams.periodFrom]?: string
  [CargoListPageSearchParams.periodTo]?: string
  [CargoListPageSearchParams.cargoType]?: Type[]
}

export function CargoFilters() {
  const { data } = useGetMe()
  const [searchParams, setSearchParams] = useSearchParams()
  const poolsParam = searchParams.getAll(CargoListPageSearchParams.pools)
  const worldAreaParam = searchParams.getAll(
    CargoListPageSearchParams.worldArea,
  )
  const cargoGradeParam = searchParams.getAll(
    CargoListPageSearchParams.cargoGrade,
  )
  const periodFromParam = searchParams.get(CargoListPageSearchParams.periodFrom)
  const periodToParam = searchParams.get(CargoListPageSearchParams.periodTo)
  const cargoTypeParam = searchParams.getAll(
    CargoListPageSearchParams.cargoType,
  )

  function onChangeHandler(values: ICargoFilters) {
    const previous = {
      [CargoListPageSearchParams.cargoGrade]:
        cargoGradeParam || defaultFilters[CargoListPageSearchParams.cargoGrade],
      [CargoListPageSearchParams.pools]: poolsParam || [],
      [CargoListPageSearchParams.worldArea]: worldAreaParam || [],
      [CargoListPageSearchParams.periodFrom]:
        periodFromParam || defaultFilters[CargoListPageSearchParams.periodFrom],
      [CargoListPageSearchParams.periodTo]:
        periodToParam || defaultFilters[CargoListPageSearchParams.periodTo],
      [CargoListPageSearchParams.cargoType]:
        cargoTypeParam || defaultFilters[CargoListPageSearchParams.cargoType],
    }

    setSearchParams({ ...previous, ...values } as any)
  }
  return (
    <Grid
      container
      spacing={4}
      sx={{
        alignItems: 'end',
        '.react-datepicker__input-container': { input: { width: '100%' } },
        '.react-datepicker__input-container > *': { width: '100%' },
      }}
    >
      <Grid size={{ xs: 12, md: 6, xl: 3, xxxl: 2 }}>
        <AreaRenderer
          disableInputValidation
          input={worldAreaParam}
          onChangeHandler={(worldArea) =>
            onChangeHandler({
              [CargoListPageSearchParams.worldArea]: worldArea,
            })
          }
          label="Load/Delivery World Area"
        />
      </Grid>

      <Grid size={{ xs: 12, md: 6, xl: 3, xxxl: 2 }}>
        <MultiItemAutocomplete
          input={poolsParam}
          label="Pool(s)"
          onChangeHandler={(e, pools) =>
            onChangeHandler({
              [CargoListPageSearchParams.pools]: pools,
            })
          }
          options={data?.activePools || []}
        />
      </Grid>

      <Grid size={{ xs: 12, md: 6, xl: 3, xxl: 2 }}>
        <MultiItemAutocomplete
          label="Cargo Grade"
          input={cargoGradeParam}
          onChangeHandler={(e, cargoGrade) =>
            onChangeHandler({
              [CargoListPageSearchParams.cargoGrade]: cargoGrade,
            })
          }
          options={[...cargoGradeOptions, ...productCodesWithCargoGrades]}
        />
      </Grid>

      <Grid size={{ xs: 12, md: 6, xl: 3, xxl: 2 }}>
        <Box sx={{ display: 'flex', gap: 2, py: 2 }}>
          <Typography variant="label" component="p">
            Quotes from
          </Typography>
          <Tooltip title="Date filter only applies to quotes with status Fixed, Failed or Withdrawn" />
        </Box>
        <DatePicker
          name="Quotes from"
          onChange={(date) => {
            onChangeHandler({
              [CargoListPageSearchParams.periodFrom]: date
                ? format(date, dateFormat)
                : undefined,
            })
          }}
          value={
            periodFromParam ||
            defaultFilters[CargoListPageSearchParams.periodFrom]
          }
        />
      </Grid>

      <Grid size={{ xs: 12, md: 6, xl: 3, xxl: 2 }}>
        <Box sx={{ display: 'flex', gap: 2, py: 2 }}>
          <Typography variant="label" component="p">
            Quotes to
          </Typography>
          <Tooltip title="Date filter only applies to quotes with status Fixed, Failed or Withdrawn" />
        </Box>
        <DatePicker
          name="Quotes to"
          onChange={(date) => {
            onChangeHandler({
              [CargoListPageSearchParams.periodTo]: date
                ? format(date, dateFormat)
                : undefined,
            })
          }}
          value={
            periodToParam || defaultFilters[CargoListPageSearchParams.periodTo]
          }
        />
      </Grid>

      <Grid size={{ xs: 12, md: 6, xl: 3, xxxl: 2 }}>
        <Box
          sx={{ display: 'flex', rowGap: 2, columnGap: 4, flexWrap: 'wrap' }}
        >
          <Switch
            name="toggle_spot"
            checked={
              !!cargoTypeParam.length
                ? cargoTypeParam
                    .map((type) => type.toLowerCase())
                    .includes('spot')
                : true
            }
            label="Show spot"
            onChange={(e) =>
              onChangeHandler({
                [CargoListPageSearchParams.cargoType]: e.target.checked
                  ? ([...cargoTypeParam, 'SPOT'] as Type[])
                  : ['T/C'],
              })
            }
          />
          <Switch
            name="toggle_tc"
            checked={
              !!cargoTypeParam.length
                ? cargoTypeParam
                    .map((type) => type.toLowerCase())
                    .includes('t/c')
                : true
            }
            label="Show T/C"
            onChange={(e) =>
              onChangeHandler({
                [CargoListPageSearchParams.cargoType]: e.target.checked
                  ? ([...cargoTypeParam, 'T/C'] as Type[])
                  : ['SPOT'],
              })
            }
          />
        </Box>
      </Grid>
      <Grid size={{ xs: 12, md: 6, xl: 3, xxxl: 2 }}>
        <TextButton onClick={() => setSearchParams()}>Reset filters</TextButton>
      </Grid>
      <Grid size={{ xs: 12, md: 6, xl: 2 }}>
        <AddCargoButton />
      </Grid>
    </Grid>
  )
}

function AddCargoButton() {
  const [searchParams, setSearchParams] = useSearchParams()

  function addCargo() {
    searchParams.set(CargoListPageSearchParams.createCargo, 'true')
    setSearchParams(searchParams)
  }

  return (
    <Box
      component={TextButton}
      onClick={addCargo}
      icon={<PlusIcon />}
      sx={{ p: 0 }}
    >
      Add Cargo
    </Box>
  )
}
