import { useSearchParams } from 'react-router-dom'
import { CargoListPageSearchParams } from '../../constants'
import { AddCargoModal } from './add-cargo-modal'
import { RecreateCargoModal } from './recreate-cargo-modal'
import { UpdateCargoModal } from './update-cargo-modal'

export function CargoModal({ onClose }: { onClose: () => void }) {
  const [searchParams] = useSearchParams()
  const createCargo = searchParams.get(CargoListPageSearchParams.createCargo)
  const editCargo = searchParams.get(CargoListPageSearchParams.editCargo)
  const recreateCargo = searchParams.get(
    CargoListPageSearchParams.recreateCargo,
  )

  if (createCargo) {
    return <AddCargoModal onClose={onClose} />
  }

  if (editCargo) {
    return <UpdateCargoModal onClose={onClose} />
  }

  if (recreateCargo) {
    return <RecreateCargoModal onClose={onClose} />
  }

  return null
}
