import { useEffect, useState } from 'react'
import { HeaderActions } from '~pages/pages-behind-login/vessel-page/vessel-header/header-actions/header-actions'
import { Z_INDEX } from '~types/z-index'
import { Box, Typography } from '@mui/material'
import { COLORS } from '~theme/colors'

const MobileHeader = ({ vessel }: { vessel: any }) => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.scrollY
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Box
      sx={{
        position: 'sticky',
        top: '56px',
        background: COLORS.greys.ultraLight,
        boxShadow: scrollPosition > 0 ? '0 0 5px rgba(0, 0, 0, 0.5)' : 'none',
        zIndex: Z_INDEX.PageHeader + 1,
        display: 'flex',
        alignItems: 'center',
        height: '64px',
        padding: '0 52px',
      }}
    >
      <Typography variant="h1" style={{ marginRight: '24px' }}>
        {vessel.vesselName}
      </Typography>
      <HeaderActions vesselData={vessel} />
    </Box>
  )
}

export default MobileHeader
