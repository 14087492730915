import { Box, TextField, Typography } from '@mui/material'
import { addDays, differenceInCalendarDays } from 'date-fns'
import { useEffect, useState } from 'react'
import { COLORS } from '~theme/colors'
import { FieldsGroupProps } from '../shared-fields'
import { DateInputField } from './date-input'

export function Laycan({
  updateFormInput,
  formInput,
  missingFields,
  disabledFields,
}: FieldsGroupProps) {
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    const calculatedOffset =
      formInput.laycanTo && formInput.laycanFrom
        ? differenceInCalendarDays(formInput.laycanTo, formInput.laycanFrom)
        : 0

    setOffset(calculatedOffset)
  }, [formInput])

  function updateOffset(value: number) {
    if (formInput.laycanFrom) {
      const laycanTo = addDays(new Date(formInput.laycanFrom), value)
      updateFormInput({ laycanTo })
      return
    }

    updateFormInput({ laycanTo: undefined })
  }

  return (
    <Box width="100%">
      <Typography
        variant="label"
        color={
          disabledFields?.includes('laycanFrom')
            ? COLORS.greys.mid
            : COLORS.secondary.darkBlue
        }
      >
        {' '}
        laycan *
      </Typography>
      <Box sx={{ display: 'flex', gap: 2, alignItems: 'baseline' }}>
        <DateInputField
          sx={{ width: '100%' }}
          onChangeHandler={(value) =>
            updateFormInput({ laycanFrom: value, laycanTo: value })
          }
          dateTime={formInput.laycanFrom}
          hasError={missingFields?.includes('laycanFrom')}
          disabled={disabledFields?.includes('laycanFrom')}
        />

        {formInput.laycanFrom && (
          <>
            <Typography variant="label">+</Typography>
            <TextField
              sx={{ mt: 2, width: '100px' }}
              type="number"
              onChange={(e) => updateOffset(Number(e.target.value))}
              value={offset}
              error={missingFields?.includes('laycanTo')}
              disabled={disabledFields?.includes('laycanTo')}
            />
          </>
        )}
      </Box>
    </Box>
  )
}
