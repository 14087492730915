type FeatureToggle = {
  key: string
  value: boolean
}

enum Mode {
  Development = 'development',
  Production = 'production',
}

export enum Environment {
  Production = 'maersktankers.digital',
  Stage = 'maersktankers-stage.digital',
  Test = 'maersktankers-test.digital',
  Local = 'localhost',
}

// Usage: environmentToggle(Environment.Stage, Environment.Test, Environment.Local)
export const environmentToggle = (...args: Environment[]) => {
  const { hostname } = window.location
  return args.some((input) => hostname.match(input))
}

const dateToggle = (date: string): boolean => {
  return new Date(date) < new Date()
}

const modeToggle = (mode: Mode): boolean => {
  return import.meta.env.MODE === mode
}

const setFeatureToggleStorage = (key: string, isEnabled: boolean): void => {
  localStorage.setItem(key, isEnabled.toString())
}

export const getFeatureToggle = (featureToggle: FeatureToggle): boolean => {
  if (featureToggle.value === true) {
    return true
  }

  const featureToggleStorage = localStorage.getItem(featureToggle.key)
  if (featureToggleStorage === 'true') {
    return true
  } else if (featureToggleStorage === 'false') {
    return false
  }

  if (import.meta.env.MODE !== 'production') {
    setFeatureToggleStorage(featureToggle.key, false)
  }
  return false
}

// Define feature toggles here

// Examples:
/*
  export const testFeature = {
    // this is used for the localstorage key
    key: 'testFeature',
    // this will be the default value and will be evaluated before the localstorage value. If this is false
    // then the localstorage will be evaluated.
    value: false,
  };
  
  export const testFeature2 = {
    // this is used for the localstorage key
    key: 'testFeature2',
    // this will always be shown in local and staging environments and will always be shown in all environments
    // after 2023-01-01.
    value: modeToggle(Mode.Staging) || dateToggle('2024-01-31'),
  };
  */

export const welcomeOnlyPoolPartnersEnabled = {
  key: 'welcome-only-pool-partners-enabled',
  value: dateToggle('2024-12-05'),
}

export const calendarEnabled = {
  key: 'calendar-enabled',
  value: false,
}

export const guidedTourEnabled = {
  key: 'guided-tour-enabled',
  value: false,
}

export const harryPotterThemeSupportEnabled = {
  key: 'harry-potter-theme-support-enabled',
  value: environmentToggle(Environment.Local, Environment.Test),
}

export const questionnairePageV2Enabled = {
  key: 'questionnaire-page-v2-enabled',
  value: false,
}

export const cargoListEnabled = {
  key: 'cargo-list-enabled',
  value: environmentToggle(
    Environment.Local,
    Environment.Test,
    Environment.Stage,
  ),
}

export const positionListV2Enabled = {
  key: 'position-list-v2-enabled',
  value: environmentToggle(
    Environment.Local,
    Environment.Test,
    Environment.Stage,
  ),
}
