import { Box, SxProps, Theme, Typography } from '@mui/material'
import { format, parseISO } from 'date-fns'

export function NonEditableCommentRenderer({
  comment,
  userName,
  createdAt,
  children,
  xlGap,
  sx,
}: {
  comment?: string
  userName?: string
  createdAt?: string
  children?: React.ReactNode
  xlGap?: boolean
  sx?: SxProps<Theme>
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: xlGap ? 4 : 1,
        width: '100%',
        justifyContent: 'space-between',
        ...sx,
      }}
    >
      {children}
      {comment && (
        <Typography
          variant="paragraph1"
          sx={{
            overflowWrap: 'anywhere',
            whiteSpace: 'pre-wrap',
          }}
        >
          {comment}
        </Typography>
      )}
      {(userName || createdAt) && (
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            whiteSpace: 'nowrap',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {userName && <Typography variant="label">{userName}</Typography>}
          {createdAt && (
            <Typography variant="graphCaption">
              {format(parseISO(createdAt), 'dd-MM-yyyy')}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  )
}
