import { Box, TextField, Typography } from '@mui/material'
import { COLORS } from '~theme/colors'
import { FieldsGroupProps } from '../shared-fields'

export function Broker({
  formInput,
  updateFormInput,
  missingFields,
  disabledFields,
}: FieldsGroupProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography
        variant="label"
        color={
          disabledFields?.includes('broker')
            ? COLORS.greys.mid
            : COLORS.secondary.darkBlue
        }
      >
        Broker
      </Typography>
      <TextField
        sx={{ mt: 2 }}
        value={formInput.broker}
        onChange={(e) => updateFormInput({ broker: e.target.value })}
        error={missingFields?.includes('broker')}
        disabled={disabledFields?.includes('broker')}
      />
    </Box>
  )
}
