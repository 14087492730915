import { useEffect, useState } from 'react'

import { usePositionListView } from '~pages/pages-behind-login/chartering/contexts/position-list-view-provider'
import AnimatedHeart from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/vessel-name-content/animated-heart'

interface Props {
  vesselIMO: string
}

const FavoriteToggle = ({ vesselIMO }: Props) => {
  const { settings, persistFavoriteVessel } = usePositionListView()
  const [fillHeartAnimation, setFillHearAnimation] = useState(false)
  const [unfillHeartAnimation, setUnfillHearAnimation] = useState(false)
  const [filled, setFilled] = useState(false)

  useEffect(() => {
    setFilled(settings?.favoriteVessels?.includes(vesselIMO) || false)
  }, [settings?.favoriteVessels])

  const handleClick = () => {
    setFilled((prev) => {
      setFillHearAnimation(prev === false)
      setUnfillHearAnimation(prev === true)
      return !prev
    })
    persistFavoriteVessel(vesselIMO)
  }

  return (
    <AnimatedHeart
      onClick={handleClick}
      filled={filled}
      animateFill={fillHeartAnimation}
      animateUnfill={unfillHeartAnimation}
    />
  )
}

export default FavoriteToggle
