import {
  DollarSlashIcon,
  theme,
  typographyStyles,
} from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import styled from 'styled-components/macro'
import { RouterLink } from '~components/atoms/router-link'
import { ConsumptionLevel } from '~pages/pages-behind-login/chartering/types/position-list-types'
import { COLORS } from '~theme/colors'

export const getConsumptionDotColor = (level: ConsumptionLevel) => {
  switch (level) {
    case ConsumptionLevel.low:
      return COLORS.citrus.mid
    case ConsumptionLevel.medium:
      return COLORS.orange.light
    case ConsumptionLevel.high:
      return COLORS.red.mid
  }
}

export const StyledRouterLink = styled(RouterLink)<{ leavingPool?: boolean }>`
  color: ${({ leavingPool }) =>
    leavingPool ? COLORS.red.dark : COLORS.secondary.midBlue};
`

export const VesselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 192px;
`

export const VesselNameTitle = styled.div<{ anchor?: HTMLElement | null }>`
  ${typographyStyles.paragraphBold2}
  display: flex;
  align-items: center;
  margin-bottom: ${theme.SPACINGS[1]};
`

export const VesselDetailBold = styled.span`
  font-weight: ${theme.FONTS.weights.bold};
`

export const VesselDetails = styled.div`
  ${typographyStyles.graphCaption}
  display: flex;
  align-items: center;
  color: ${COLORS.secondary.darkBlue};
  width: 192px;
`

export const VesselNameWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 340px;
  padding-right: ${theme.SPACINGS[2]};
  position: relative;
`

export const TradeSymbolsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

export const TradeSymbolsContentWrapper = styled.div`
  flex-shrink: 0;
`

export const TradeSymbolBox = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '20px',
      height: '20px',
      marginRight: '2px',
    }}
  >
    {children}
  </Box>
)

export const ConsumptionDot = styled.span<{
  consumptionLevel: ConsumptionLevel
}>`
  --dot-size: 12px;
  display: inline-block;
  width: var(--dot-size);
  height: var(--dot-size);
  min-width: var(--dot-size);
  min-height: var(--dot-size);
  border-radius: 100%;
  background-color: ${({ consumptionLevel }) =>
    getConsumptionDotColor(consumptionLevel)};
`

export const RejectionsIconWrapper = styled.div`
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    right: 50%;
    border-radius: 100%;
    transform: translate(50%, -50%);
  }
`

export const RedeliveryPopupContent = styled.div`
  ${typographyStyles.caption}
  background-color: ${COLORS.primary.white};
  padding: ${theme.SPACINGS[2]};
  border-radius: 2px;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
`

export const NoWorldAreaWrapper = styled.div`
  margin-left: 0.3rem;
`

export const NoWorldAreaPopupContent = styled.div`
  ${typographyStyles.caption}
  background-color: ${COLORS.primary.white};
  padding: ${theme.SPACINGS[2]};
  border-radius: 2px;
  box-shadow: ${theme.SHADOWS.cardShadow};
`

export const StyledDollarSlashIcon = styled(DollarSlashIcon)``

export const ActualImpairedEarningsWrapper = styled.div``
