export enum PageRoute {
  LANDING = '/',
  ADMIN_SETTINGS = '/admin/settings',
  AUTH_CALLBACK = '/authcallback',
  VERIFY_EMAIL = '/verify-email',
  UNAUTHORIZED = '/unauthorized',
  FEATURE_FLAG = '/feature-flag/*',
  CREATE_USER = '/create-user',
  POOL_PARTNERS = '/pool-partners',
  POOL_PARTNER = '/pool-partners/:poolPartnerKey',
  USERS = '/users',
  USER = '/users/:id',
  REJECTIONS = 'vessels/tools/rejections',
  SIRE = 'vessels/tools/sire',
  CDI = 'vessels/tools/cdi',
  VESSELS = '/vessels',
  VESSEL = '/vessels/:vesselId/*',
  VESSEL_DASHBOARD = 'vessel-dashboard',
  PARTNER_DASHBOARD = 'partner-dashboard',
  OPS_DASHBOARD = 'ops-dashboard',
  QUESTIONNAIRE = 'questionnaire',
  BASIC_VESSEL_DATA = 'basic-vessel-data',
  PORT_ESSENTIALS = 'port-essentials',
  POOL_RESTRICTIONS = 'pool-restrictions',
  VETTING = 'vetting',
  DOCUMENTS = 'documents',
  VOYAGES = 'voyages',
  POOL_POINTS = 'pool-points',
  POSITION_LIST_DECOMMISSIONED = 'position-list',
  CHARTERING = 'chartering',
  POSITION_LIST = '/chartering/position-list',
  POSITION_LIST_V2 = '/chartering/position-list-v2',
  PROFILE = '/profile',
  MAP = '/chartering/map',
  EXPOSURE = '/chartering/exposure',
  CARGO_LIST = '/chartering/cargo-list',
  EMISSIONS = '/panel/emission-universe/:pool',
}
