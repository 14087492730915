import { ColumnDefinition } from '~pages/pages-behind-login/chartering/types/position-list-types'

export const columnsConfig: ColumnDefinition[] = [
  {
    label: 'Vessel Name',
    value: 'vessel',
    sortable: true,
    fixed: true,
    permanent: true,
    width: 340,
  },

  {
    label: 'Open Date',
    value: 'openDate',
    sortable: true,
    permanent: true,
    width: 70,
  },
  {
    label: 'Open Port',
    value: 'openPort',
    sortable: true,
    permanent: true,
    width: 150,
  },
  { label: 'L3C', value: 'voyages', sortable: false, width: 200 },
  { label: 'Comments', value: 'comments', sortable: false, width: 300 },
  {
    label: 'Status',
    value: 'status',
    sortable: false,
    fixed: true,
    permanent: true,
    width: 140,
  },
  {
    label: '',
    value: 'showCurrentVoyage',
    sortable: false,
    fixed: true,
    permanent: true,
    width: 40,
  },
]

export const vesselSpecsConfig: ColumnDefinition[] = [
  { label: 'Year built', value: 'yearBuilt', sortable: true, width: 70 },
  { label: 'IMO', value: 'imoClass', sortable: true, width: 60 },
  { label: 'CBM', value: 'cbm', sortable: true, width: 70 },
  { label: 'DWT', value: 'dwt', sortable: true, width: 80 },
  { label: 'BWTS', value: 'bwts', sortable: true, width: 70 },
  { label: 'Scrubber', value: 'scrubber', sortable: true, width: 80 },
  { label: 'Stern line', value: 'sternLine', sortable: true, width: 80 },
  { label: 'ICE', value: 'iceClass', sortable: true, width: 60 },
  { label: 'IGS', value: 'igs', sortable: true, width: 60 },
  { label: 'Flag', value: 'flag', sortable: true, width: 100 },
  { label: 'Operator', value: 'operator', sortable: true, width: 120 },
  {
    label: 'Last update GMT',
    value: 'lastUpdateGmt',

    sortable: true,
    width: 80,
  },
  { label: 'LOA', value: 'loa', sortable: true },
  { label: 'Draft', value: 'summerDraft', sortable: true },
  { label: 'PBL', value: 'pbl', sortable: true },
  { label: 'Beam', value: 'beam', sortable: true },
  { label: 'Tank coating', value: 'tankCoating', sortable: true, width: 200 },
  {
    label: 'Consumption',
    value: 'bunkerConsumption',
    sortable: true,
    width: 120,
  },
  { label: 'Next dry dock', value: 'nextDryDock', sortable: true, width: 100 },
  { label: 'Last SIRE', value: 'lastSire', sortable: true, width: 100 },
  { label: 'US COC', value: 'usCoc', sortable: true, width: 110 },
  { label: 'Moulded depth', value: 'mouldedDepth', sortable: true, width: 80 },
  {
    label: 'IGS - Flue gas vs IGG',
    value: 'igsSupply',
    width: 150,
    sortable: true,
  },
  { label: 'DPP', value: 'dpp', sortable: true, width: 60 },
]

export const loadAreaConfig: ColumnDefinition[] = [
  {
    label: 'Amsterdam',
    columnLabel: 'ETA AMS',
    value: 'AMSTERDAM',
    sortable: true,
    width: 90,
  },
  {
    label: 'Fujairah',
    columnLabel: 'ETA FUJ',
    value: 'FUJAIRAH',
    sortable: true,
    width: 90,
  },
  {
    label: 'Gibraltar',
    columnLabel: 'ETA GIB',
    value: 'GIBRALTAR',
    sortable: true,
    width: 90,
  },
  {
    label: 'Houston',
    columnLabel: 'ETA HOU',
    value: 'HOUSTON',
    sortable: true,
    width: 90,
  },
  {
    label: 'Malta',
    columnLabel: 'ETA MLT',
    value: 'MALTA OPL',
    sortable: true,
    width: 90,
  },
  {
    label: 'Sikka',
    columnLabel: 'ETA SIK',
    value: 'SIKKA',
    sortable: true,
    width: 90,
  },
  {
    label: 'Singapore',
    columnLabel: 'ETA SGP',
    value: 'SINGAPORE',
    sortable: true,
    width: 90,
  },
  {
    label: 'Yanbu',
    columnLabel: 'ETA YBU',
    value: 'YANBU',
    sortable: true,
    width: 90,
  },
  {
    label: 'Yosu',
    columnLabel: 'ETA YSU',
    value: 'YOSU',
    sortable: true,
    width: 90,
  },
]

export const worldAreaConfig: ColumnDefinition[] = [
  { label: 'Level 1', value: 'level1', sortable: true, width: 90 },
  { label: 'Level 2', value: 'level2', sortable: true, width: 100 },
  { label: 'Level 3', value: 'level3', sortable: true, width: 120 },
]
