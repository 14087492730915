import { Typography, WarningIcon } from '@maersktankersdigital/web-components'
import { Popup } from '@mui/base/Unstable_Popup/Popup'
import { isWithinInterval, subMonths } from 'date-fns'
import * as React from 'react'
import { useState } from 'react'
import ColorLabelShort from '~components/atoms/color-label-short'
import { PositionListConsumptionTooltipContent } from '~components/organisms/tooltips/position-list-consumption-content'
import {
  PositionListRejectionsTooltipContent,
  StyledHeadline,
  StyledPopupContent,
} from '~components/organisms/tooltips/position-list-rejections-tooltip-content'
import { Tooltip } from '~components/tooltip'
import { CII } from '~hooks/queries/vessels/data/types/vessel-dashboard'
import {
  ActualImpairedEarningsWrapper,
  ConsumptionDot,
  RejectionsIconWrapper,
  StyledDollarSlashIcon,
  TradeSymbolBox,
  TradeSymbolsWrapper,
} from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/vessel-name-content/vessel-name.styles'
import {
  PositionVesselData,
  Rejection,
} from '~pages/pages-behind-login/chartering/types/position-list-types'
import { COLORS } from '~theme/colors'
import { Voyage, VoyageStatus } from '~types/itinerary.types'
import { Z_INDEX } from '~types/z-index'
import { capitalizeStringWords } from '~utils/capitalize-string-words'

interface Props {
  vesselData: PositionVesselData
}

function TradeSymbolsContent({ vesselData }: Props) {
  const [rejectionsAnchor, setRejectionsAnchor] = useState<null | HTMLElement>(
    null,
  )
  const biofuelCapable = vesselData.biofuelCapable === 'Yes'
  const [earningsAnchor, setEarningsAnchor] = useState<null | HTMLElement>(null)
  const [consumptionAnchor, setConsumptionAnchor] =
    useState<null | HTMLElement>(null)
  const rejectionsArray = vesselData.rejections || []
  const { bunkerConsumption, ciiRating } = vesselData

  const handleRejectionsMouseOver = (event: React.MouseEvent<HTMLElement>) => {
    setRejectionsAnchor(event.currentTarget)
  }

  const handlerejectionsMouseLeave = () => {
    setRejectionsAnchor(null)
  }

  const handleEarningsMouseOver = (event: React.MouseEvent<HTMLElement>) => {
    setEarningsAnchor(event.currentTarget)
  }

  const handleEarningsMouseLeave = () => {
    setEarningsAnchor(null)
  }

  const handleConsumptionMouseOver = (event: React.MouseEvent<HTMLElement>) => {
    setConsumptionAnchor(event.currentTarget)
  }

  const handleConsumptionMouseLeave = () => {
    setConsumptionAnchor(null)
  }

  const rejectionsOpen = Boolean(rejectionsAnchor)
  const consumptionOpen = Boolean(consumptionAnchor)
  const earningsOpen = Boolean(earningsAnchor)
  const isValidCiiRating = ciiRating && Object.values(CII).includes(ciiRating)
  const lastScheduledVoyage = vesselData.voyages.find(
    (voyage: Voyage) =>
      voyage.status === VoyageStatus.SCHEDULED ||
      voyage.status === VoyageStatus.COMMENCED,
  )
  const lastSireWithin4Months = vesselData.lastSire.inspectionDate
    ? isWithinInterval(new Date(), {
        start: subMonths(new Date(vesselData.lastSire.inspectionDate), 4),
        end: new Date(),
      })
    : true
  const showWarning = rejectionsArray.length > 0 || !lastSireWithin4Months

  return (
    <TradeSymbolsWrapper>
      <TradeSymbolBox>
        {bunkerConsumption?.ladenTonsPerDayPoolRating && (
          <>
            <ConsumptionDot
              consumptionLevel={bunkerConsumption?.ladenTonsPerDayPoolRating}
              onMouseOver={handleConsumptionMouseOver}
              onMouseLeave={handleConsumptionMouseLeave}
            />
            <Popup
              open={consumptionOpen}
              anchor={consumptionAnchor}
              placement="top-start"
              offset={{ mainAxis: 10 }}
              style={{ zIndex: Z_INDEX.Popup }}
            >
              <PositionListConsumptionTooltipContent
                bunkerConsumption={bunkerConsumption}
              />
            </Popup>
          </>
        )}
      </TradeSymbolBox>

      <TradeSymbolBox>
        {ciiRating && isValidCiiRating && (
          <ColorLabelShort
            variant="CII"
            variantType={ciiRating}
            label={ciiRating}
            weight="bold"
            size="small"
          />
        )}
      </TradeSymbolBox>

      <TradeSymbolBox>
        {biofuelCapable && (
          <Tooltip
            icon="fuel-eu"
            title="Biofuel Capable"
            size={16}
            placement="top"
            style={{
              marginLeft: '2px',
              cursor: 'auto',
            }}
          />
        )}
      </TradeSymbolBox>

      <TradeSymbolBox>
        {showWarning && (
          <>
            <RejectionsIconWrapper
              onMouseOver={handleRejectionsMouseOver}
              onMouseLeave={handlerejectionsMouseLeave}
            >
              <WarningIcon size={16} color={COLORS.red.dark} />
            </RejectionsIconWrapper>
            <Popup
              open={rejectionsOpen}
              anchor={rejectionsAnchor}
              placement="top-start"
              offset={{ mainAxis: 10 }}
              style={{ zIndex: 2 }}
            >
              <PositionListRejectionsTooltipContent
                rejections={rejectionsArray.map((e: Rejection) => e)}
                lastSireWithin4Months={lastSireWithin4Months}
              />
            </Popup>
          </>
        )}
      </TradeSymbolBox>
      <TradeSymbolBox>
        {lastScheduledVoyage?.actualImpairedEarnings && (
          <>
            <ActualImpairedEarningsWrapper
              onMouseOver={handleEarningsMouseOver}
              onMouseLeave={handleEarningsMouseLeave}
            >
              <StyledDollarSlashIcon size={16} color={COLORS.red.dark} />
            </ActualImpairedEarningsWrapper>
            <Popup
              open={earningsOpen}
              anchor={earningsAnchor}
              placement="top-start"
              offset={{ mainAxis: 10 }}
              style={{ zIndex: 2 }}
            >
              <StyledPopupContent style={{ wordBreak: 'break-word' }}>
                <StyledHeadline>
                  {capitalizeStringWords(
                    lastScheduledVoyage.actualImpairedEarnings,
                  )}
                </StyledHeadline>
                <Typography variant="paragraph2">
                  {lastScheduledVoyage?.actualImpairedEarningsComment || ''}
                </Typography>
              </StyledPopupContent>
            </Popup>
          </>
        )}
      </TradeSymbolBox>
    </TradeSymbolsWrapper>
  )
}

export default TradeSymbolsContent
