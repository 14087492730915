import {
  ChevronLeftIcon,
  TextButton,
} from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { useConstructLinkWithPool } from '../hooks/use-construct-link-with-pool'

interface Props {
  className?: string
  forceHome?: boolean
}

export function GoBackButton({ className, forceHome = false }: Props) {
  const navigate = useNavigate()
  const isTechnicalManager = useHasScope(ExactScopeName.technicalManager)
  const dashboardLink = useConstructLinkWithPool('/panel/dashboard/:pool')

  const link = isTechnicalManager ? 'panel/financials' : dashboardLink
  const label = !forceHome
    ? ''
    : isTechnicalManager
      ? 'Financials'
      : 'Dashboard'

  return (
    <Box
      sx={{
        margin: 0,
        padding: 0,
      }}
      component={TextButton}
      icon={<ChevronLeftIcon fontSize={20} />}
      onClick={() => (forceHome ? navigate(link) : navigate(-1))}
      className={className}
    >
      Go Back {label}
    </Box>
  )
}
