import { format, subDays } from 'date-fns'
import { ICargoFilters } from './componenets/cargo-filters'
import { CargoStatus } from './content/select-n-autocomplete-options'

export const minCharsForAutocomplete = 3

export const toBeNamedText = 'TBN'
export const tradingWorldwide = 'Trading worldwide'

export const dateFormat = 'dd-MM-yyyy'

export const statusesNeedingPeriodFilter: CargoStatus[] = [
  CargoStatus.Fixed,
  CargoStatus.Withdrawn,
  CargoStatus.Failed,
]

export const cargoStatusesWithHiddenFormFields = [
  CargoStatus.Open,
  CargoStatus.Poss,
]

export enum CargoListPageSearchParams {
  readCargo = 'read-cargo',
  editCargo = 'edit-cargo',
  recreateCargo = 'recreate-cargo',
  createCargo = 'create-cargo',
  cargoGrade = 'cargo-grade',
  pools = 'pools',
  worldArea = 'world-area',
  periodFrom = 'period-from',
  periodTo = 'period-to',
  cargoType = 'cargo-type',
  orderByDate = 'order-by-date',
}

const numberOfDaysInPast = 14

export const defaultFilters: ICargoFilters = {
  [CargoListPageSearchParams.cargoGrade]: [],
  [CargoListPageSearchParams.pools]: [],
  [CargoListPageSearchParams.worldArea]: [],
  [CargoListPageSearchParams.periodFrom]: format(
    subDays(new Date(), numberOfDaysInPast),
    dateFormat,
  ),
  [CargoListPageSearchParams.periodTo]: format(new Date(), dateFormat),
  [CargoListPageSearchParams.cargoType]: ['SPOT', 'T/C'],
}
