import { useQuery } from '@tanstack/react-query'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'

export function useGetCharterer({
  disabled,
  chartererName,
}: {
  disabled?: boolean
  chartererName?: string
}) {
  const fetch = useFetch<string[]>()
  let url = '/cargo/charterer'

  if (chartererName) {
    url = `${url}?chartererName=${chartererName}`
  }

  return useQuery({
    queryKey: [url],
    queryFn: () => fetch(`${apiBase()}${url}`),
    enabled: !disabled,
  })
}
