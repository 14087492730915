import { Box as MuiBox, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { IUserInfoReadResponse } from '~api/user/read'
import { Box } from '~components/atoms/box'
import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import { useGetMe } from '~hooks/queries/me/use-get-me'
import { PatchMePayload, usePatchMeVT } from '~hooks/queries/me/use-patch-me-vt'
import AnimatedHeart from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/vessel-name-content/animated-heart'
import { gtm } from '~utils/gtm'
import { StyledFavoritesToggler } from './styles'

interface VesselFavoriteTogglerProps {
  showText?: boolean
  vesselId: string
  vesselName: string
}

export function VesselFavoriteToggler({
  vesselName,
  vesselId,
  showText = true,
}: VesselFavoriteTogglerProps) {
  const [isFavorite, setIsFavorite] = useState(false)
  const { setStatus } = useAnimatedAlert()
  const { data } = useGetMe()
  const { status, mutateAsync: patchMe } = usePatchMeVT()

  useEffect(() => {
    if (status === 'error') {
      setStatus('Something went wrong. Please try again later.', 5000, 'error')
    }
  }, [status])

  useEffect(() => {
    if (!data) return
    const isFavorite = data.favoriteVesselsNames?.some(
      (item) => item._id === vesselId,
    )
    setIsFavorite(isFavorite)
  }, [data, vesselId])

  if (!data) return null

  function onClickHandler() {
    setIsFavorite(!isFavorite)
    patchMe(constructPayload(isFavorite, vesselId, data))

    if (!isFavorite) {
      trackClick(vesselName)
    }
  }

  return (
    <StyledFavoritesToggler>
      <Box dataTest="favorite-toggler" noStyles>
        <MuiBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={onClickHandler}
        >
          <AnimatedHeart filled={isFavorite} />
          {showText && (
            <Typography sx={{ ml: 2 }} component="span">
              {isFavorite ? 'Unmark as favorite' : 'Mark as favorite'}
            </Typography>
          )}
        </MuiBox>
      </Box>
    </StyledFavoritesToggler>
  )
}

function trackClick(vesselName: string) {
  gtm.pushEvent('internal_click', {
    click_type: 'vessel_favorited',
    click_subtype: vesselName,
  })
}

function constructPayload(
  isFavorite: boolean,
  vesselId: string,
  data?: IUserInfoReadResponse,
): PatchMePayload {
  const favouritesList = data?.settings?.vessels?.favourites || []
  const favourites = isFavorite
    ? favouritesList.filter((item) => item !== vesselId)
    : [...favouritesList, vesselId]

  return {
    settings: {
      vessels: {
        favourites,
      },
    },
  }
}
