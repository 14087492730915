import { CloseIcon } from '@maersktankersdigital/web-components'
import {
  ComponentsOverrides,
  ComponentsProps,
  ComponentsVariants,
} from '@mui/material'
import { COLORS } from '~theme/colors'

export interface MuiChipData {
  defaultProps?: ComponentsProps['MuiChip']
  styleOverrides?: ComponentsOverrides['MuiChip']
  variants?: ComponentsVariants['MuiChip']
}

export const MuiChip: MuiChipData = {
  defaultProps: {
    deleteIcon: <CloseIcon />,
  },
  styleOverrides: {
    root: {
      backgroundColor: COLORS.secondary.lightBlue,
      borderRadius: '4px',
    },
  },
}
