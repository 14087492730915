import { PageWrapper } from '@maersktankersdigital/web-components'
import { Paper, Table, TableContainer } from '@mui/material'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { NotificationsBanner } from '~components/notifications/notifications-banner'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { PageRoute } from '~routing/constants/page-route'
import { cargoListEnabled, getFeatureToggle } from '~utils/feature-toggles'
import PageHeader from '../v2/components/page-header'
import { CargoFilters } from './componenets/cargo-filters'
import { CargoModal } from './componenets/cargo-modal/cargo-modal'
import { CargoTableSection } from './componenets/cargo-table/cargo-table-section'
import { CargoListPageSearchParams } from './constants'
import { CargoStatus } from './content/select-n-autocomplete-options'
import { useCargoSubscription } from './hooks/use-cargo-subscription'

export default function CargoListPage() {
  const hasFeatureFlagScope = useHasScope(ExactScopeName.cargoList)
  const featureEnabled =
    getFeatureToggle(cargoListEnabled) || hasFeatureFlagScope

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const isModalOpen =
    searchParams.get(CargoListPageSearchParams.editCargo) ||
    searchParams.get(CargoListPageSearchParams.createCargo) ||
    searchParams.get(CargoListPageSearchParams.recreateCargo)

  useCargoSubscription()

  useEffect(() => {
    if (!featureEnabled) {
      navigate(PageRoute.POSITION_LIST)
    }
  }, [navigate])

  function onCloseHandler() {
    searchParams.delete(CargoListPageSearchParams.editCargo)
    searchParams.delete(CargoListPageSearchParams.createCargo)
    searchParams.delete(CargoListPageSearchParams.recreateCargo)
    setSearchParams(searchParams)
  }

  return (
    <>
      <Helmet>
        <title>InSite Digital | Chartering | Cargo List</title>
      </Helmet>

      <PageHeader />

      <NotificationsBanner />

      <PageWrapper variant="wide">
        {isModalOpen && <CargoModal onClose={onCloseHandler} />}

        <CargoFilters />

        <TableContainer component={Paper} sx={{ boxShadow: 'none', mb: 21 }}>
          <Table
            sx={{
              borderCollapse: 'separate',
              borderSpacing: '0px 4px',
              '& td, th': { border: 'none' },
            }}
          >
            <CargoTableSection
              statuses={[CargoStatus.Open, CargoStatus.Poss]}
              title="Open / Poss"
            />
            <CargoTableSection
              statuses={[CargoStatus.OnSubs]}
              title="On Subs"
            />
            <CargoTableSection
              statuses={[
                CargoStatus.Fixed,
                CargoStatus.Failed,
                CargoStatus.Withdrawn,
              ]}
              title="Fixed / Failed / Withdrawn"
            />
          </Table>
        </TableContainer>
      </PageWrapper>
    </>
  )
}
