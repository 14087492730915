export const apiStatusMessages = {
  success: {
    post: 'Cargo added',
    patch: 'Cargo updated',
    extendedPatch: ({
      action,
      vesselName,
    }: {
      action: string
      vesselName: string
    }) =>
      `The cargo was successfully ${action} and the status of ${vesselName} was updated on the position list.`,
    delete: 'Cargo deleted',
  },
  error: {
    post: 'Failed to create cargo',
    patch: 'Failed to update cargo',
    delete: 'Failed to delete cargo',
  },
}

export const notFound = 'Cargo not found'

export const missingRequired = 'Some required fields are missing'
