import { useEffect, useState } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { CategoricalChartState } from 'recharts/types/chart/types'
import { OpenVesselData } from '~api/gql/generated/graphql'
import { COLORS } from '~theme/colors'

import {
  CargoGroupCount,
  OpenVessels,
  useExposureFilters,
} from '~pages/pages-behind-login/chartering/contexts/exposure-filter-provider'
import {
  barStyles,
  LegendText,
} from '~pages/pages-behind-login/chartering/exposure-page/exposure-filter-bar/exposure-filter-bar.styles'
import { axisStyles } from '~pages/pages-behind-login/chartering/exposure-page/exposure-table/exposure-table.styles'
import { CustomRegionTick } from '~pages/pages-behind-login/chartering/exposure-page/exposure-table/region-tick'
import { TableTooltip } from '~pages/pages-behind-login/chartering/exposure-page/exposure-table/table-tool-tip'
import { WeekLabel } from '~pages/pages-behind-login/chartering/exposure-page/exposure-table/week-label'
import { CustomWeekTick } from '~pages/pages-behind-login/chartering/exposure-page/exposure-table/week-tick'
import { useTotalExposureCount } from '~pages/pages-behind-login/chartering/hooks/use-total-exposure-count'
import { CargoTypeEnum } from '~pages/pages-behind-login/chartering/types/exposure-types'

export const formatLegendText = (value: string) => {
  if (!value) return null

  return (
    <LegendText style={{ color: COLORS.secondary.darkBlue }}>
      {value}
    </LegendText>
  )
}

interface Props {
  onChartClick: (data: CategoricalChartState) => void
}

export interface ExposureCount {
  exposureArea: string
  vessels: OpenVesselData[]
  vesselCount: CargoGroupCount
  vesselPercentage: CargoGroupCount
}

function ExposureTable({ onChartClick }: Props) {
  const { filters, filteredData, showBothCargoGroups } = useExposureFilters()
  const [renderedData, setRenderedData] = useState<
    OpenVessels[] | ExposureCount[]
  >()
  const totalExposure = useTotalExposureCount()

  const onlyDirty =
    filters?.cargoGroup.includes(CargoTypeEnum.DIRTY) &&
    filters.cargoGroup.length === 1

  useEffect(() => {
    // When showing 12 or more objects, insert an empty object after every group of weeks for spacing
    const insertEmptyObject = (arr: any[]) => {
      const numWeeks = filters?.weeks?.includes('ALL')
        ? 4
        : filters?.weeks?.length || 1

      if (arr.length < 12) return arr

      return arr.reduce((acc, curr, index) => {
        acc.push(curr)
        // Check if an empty object should be added for spacing
        if ((index + 1) % numWeeks === 0 && index !== arr.length - 1) {
          acc.push({ exposureArea: curr.exposureArea }) // Add spacing object
        }
        return acc
      }, [])
    }

    setRenderedData(insertEmptyObject(filteredData?.openVessels || []))
  }, [filteredData])

  useEffect(() => {
    if (filters?.totalExposure) {
      setRenderedData(totalExposure)
    }
  }, [filters?.totalExposure, totalExposure])

  const getDataKey = (group?: 'cleanWithUnknown' | 'dirty') => {
    if (showBothCargoGroups) {
      if (filters?.showPercentage) {
        return `vesselPercentage.${group}`
      }
      return `vesselCount.${group}`
    }
    if (filters?.cargoGroup[0] === CargoTypeEnum.CLEAN) {
      return filters?.showPercentage
        ? 'vesselPercentage.clean'
        : 'vesselCount.clean'
    }
    if (filters?.cargoGroup[0] === CargoTypeEnum.DIRTY) {
      return filters?.showPercentage
        ? 'vesselPercentage.dirty'
        : 'vesselCount.dirty'
    }
    return filters?.showPercentage
      ? 'vesselPercentage.total'
      : 'vesselCount.total'
  }

  return (
    <ResponsiveContainer width="99%" aspect={3}>
      <BarChart
        data={renderedData}
        barGap={0}
        barCategoryGap={0.5}
        margin={{ left: 0 }}
        onClick={onChartClick}
        style={{ cursor: 'pointer' }}
      >
        <Legend
          verticalAlign="top"
          align="left"
          iconType="circle"
          iconSize={8}
          formatter={formatLegendText}
        />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey="fromDate"
          axisLine={false}
          tickLine={false}
          interval={0}
          tick={<CustomWeekTick />}
          height={16}
          tickMargin={21}
          label={{
            content: <WeekLabel />,
            position: 'insideBottomLeft',
            className: 'week-x-label',
          }}
          padding={{ left: 42 }}
        />
        <XAxis
          dataKey="exposureArea"
          axisLine={false}
          tickLine={false}
          allowDuplicatedCategory={false}
          xAxisId="regionAxis"
          interval={0}
          tick={<CustomRegionTick />}
          padding={{ left: 42 }}
        />
        <YAxis
          style={axisStyles}
          axisLine={false}
          allowDecimals={false}
          tickLine={false}
          width={30}
          unit={filters?.showPercentage ? ' %' : ''}
        />
        <Tooltip
          content={<TableTooltip />}
          filterNull
          cursor={{
            fill: COLORS.primary.maerskBlue,
            fillOpacity: '10%',
          }}
        />

        {showBothCargoGroups ? (
          <>
            <Bar
              dataKey={getDataKey('cleanWithUnknown')}
              fill={COLORS.primary.maerskBlue}
              name="Maersk Tankers Clean"
              barSize={8}
              stackId={1}
            >
              {renderedData?.map((entry, index) => {
                const radius = entry?.vesselCount?.dirty
                  ? [0, 0, 7.5, 7.5] //Flat top
                  : [7.5, 7.5, 7.5, 7.5] //Round top

                // @ts-ignore
                return <Cell key={`cell-${index}`} radius={radius} />
              })}
            </Bar>
            <Bar
              dataKey={getDataKey('dirty')}
              fill={COLORS.greys.dark}
              name="Maersk Tankers Dirty"
              barSize={8}
              stackId={1}
            >
              {renderedData?.map((entry, index) => {
                const radius = entry?.vesselCount?.cleanWithUnknown
                  ? [7.5, 7.5, 0, 0] //Flat bottom
                  : [7.5, 7.5, 7.5, 7.5] //Round bottom

                // @ts-ignore
                return <Cell key={`cell-${index}`} radius={radius} />
              })}
            </Bar>
          </>
        ) : (
          <Bar
            dataKey={getDataKey()}
            fill={onlyDirty ? COLORS.greys.dark : COLORS.primary.maerskBlue}
            name="Maersk Tankers"
            {...barStyles}
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  )
}

export default ExposureTable
