import { cargoStatusesWithHiddenFormFields } from '../../constants'
import { CargoStatus } from '../../content/select-n-autocomplete-options'
import { FormInput, Type } from '../cargo-modal/add-cargo-modal'

const sharedRequiredKeys: (keyof FormInput)[] = [
  'tradingType',
  'privateAndConfidential',
  'dateQuoted',
  'pic',
  'status',
  'cargoGrade',
  'pool',
  'laycanFrom',
  'laycanTo',
  'charterer',
]

const spotRequiredKeys: (keyof FormInput)[] = ['quantity', 'quantityType']
const requiredHidableFormFields: (keyof FormInput)[] = ['vessel']

export function getRequiredKeys(type?: Type, status?: CargoStatus) {
  const fields =
    type === 'T/C'
      ? sharedRequiredKeys
      : [...sharedRequiredKeys, ...spotRequiredKeys]
  return !status || cargoStatusesWithHiddenFormFields.includes(status)
    ? fields
    : [...fields, ...requiredHidableFormFields]
}

export function getMissingRequiredFields(input: FormInput) {
  const requiredKeys = getRequiredKeys(input.tradingType, input.status)

  const missingKeys = (requiredKeys as Array<keyof FormInput>).filter(
    (key) =>
      input[key] === undefined ||
      input[key] === null ||
      input[key] === '' ||
      (Array.isArray(input[key]) && input[key].length === 0),
  )

  return missingKeys
}
