import { lazy } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import StoreProvider from 'src/context/store-provider'
import UserProvider from 'src/context/user-provider'
import { ExactScopeName } from '~hooks/permissions/use-has-scope'
import VesselPage from '~pages/pages-behind-login/vessel-page/vessel-page'
import { PageRoute } from '~routing/constants/page-route'
import { ExtendedRouteObject } from '../routes'

export const routesBehindLogin: ExtendedRouteObject[] = [
  {
    path: PageRoute.POSITION_LIST_DECOMMISSIONED,
    Component: lazy(
      () =>
        import(
          '../../pages/pages-behind-login/chartering/position-list-page/position-list-decommissioned'
        ),
    ),
    handle: { disableLeftNavigation: true },
  },
  {
    path: PageRoute.POSITION_LIST_V2,
    Component: lazy(
      () =>
        import('../../pages/pages-behind-login/chartering/v2/position-list-v2'),
    ),
    handle: { disableLeftNavigation: true },
  },
  {
    path: 'vessels',
    Component: lazy(
      () => import('../../pages/pages-behind-login/vessels-page/vessels-page'),
    ),
    handle: { disableLeftNavigation: true },
  },
  {
    path: 'vessels/:vesselId/*',
    Component: lazy(() =>
      Promise.resolve({
        default: () => (
          <UserProvider>
            <StoreProvider>
              <VesselPage />
            </StoreProvider>
          </UserProvider>
        ),
      }),
    ),
    handle: { withHamburger: true, leftNavigationPage: 'vessels' },
    children: [
      {
        path: 'vessel-dashboard',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/onboarded-vessel/vessel-dashboard-page/vessel-dashboard-page'
            ),
        ),
      },
      {
        path: 'ops-dashboard',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/onboarded-vessel/ops-dashboard-page/ops-dashboard-page'
            ),
        ),
      },
      {
        path: 'basic-vessel-data',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/onboarded-vessel/basic-vessel-data-page/basic-vessel-data-page'
            ),
        ),
      },
      {
        path: 'documents',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/onboarded-vessel/documents-page/documents-page'
            ),
        ),
      },
      {
        path: 'questionnaire',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/onboarded-vessel/questionnaire-page/questionnaire-page'
            ),
        ),
      },
      {
        path: 'partner-dashboard',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/onboarded-vessel/partner-dashboard-page/partner-dashboard-page'
            ),
        ),
      },
      {
        path: 'port-essentials',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/onboarded-vessel/port-essentials-page/port-essentials-page'
            ),
        ),
      },
      {
        path: 'pool-restrictions',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/onboarded-vessel/pool-restrictions-page/pool-restrictions-page'
            ),
        ),
      },
      {
        path: 'vetting',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/onboarded-vessel/vetting/vetting-page-wrapper'
            ),
        ),
      },
      {
        path: 'voyages',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/onboarded-vessel/voyages-page/voyages-page'
            ),
        ),
      },
      {
        path: 'pool-points',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/onboarded-vessel/pool-points-page/pool-points-page'
            ),
        ),
      },
    ],
  },
  {
    path: 'panel/dashboard/:pool',
    Component: lazy(
      () => import('../../pages/pages-behind-login/dashboard/dashboard'),
    ),
    handle: { disableLeftNavigation: true },
  },
  {
    path: 'help',
    Component: lazy(() => Promise.resolve({ default: () => <Outlet /> })),
    handle: { disableLeftNavigation: true },
    children: [
      {
        index: true,
        Component: lazy(
          () => import('../../pages/pages-behind-login/help/help-home'),
        ),
      },
      {
        path: 'emissions',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/help/sub-pages/help-emissions'
            ),
        ),
      },
      {
        path: 'bunker-adjustment',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/help/sub-pages/help-bunker-adjustment'
            ),
        ),
      },
      {
        path: 'finance-and-reporting',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/help/sub-pages/help-finance-and-reporting'
            ),
        ),
      },
      {
        path: 'search/:searchQuery',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/help/sub-pages/search-results'
            ),
        ),
      },
    ],
  },
  {
    path: 'panel/documents/:pool',
    Component: lazy(
      () => import('../../pages/pages-behind-login/documents/documents'),
    ),
    handle: {
      withPools: true,
      restrictedTo: [
        ExactScopeName.poolPartner,
        ExactScopeName.maerskTankersUser,
      ],
      disableLeftNavigation: true,
    },
  },
  {
    path: 'panel/emission-universe/:pool/:reportType',
    Component: lazy(
      () => import('../../pages/pages-behind-login/emissions/emission'),
    ),
    handle: {
      withPools: true,
      restrictedTo: [
        ExactScopeName.poolPartner,
        ExactScopeName.maerskTankersUser,
      ],
      disableLeftNavigation: true,
    },
  },
  {
    path: 'panel/emission-universe/:pool',
    Component: lazy(
      () => import('../../pages/pages-behind-login/emissions/emissions'),
    ),
    handle: {
      withPools: true,
      restrictedTo: [
        ExactScopeName.poolPartner,
        ExactScopeName.maerskTankersUser,
      ],
      disableLeftNavigation: true,
    },
  },
  {
    path: 'panel/financials',
    Component: lazy(() => Promise.resolve({ default: () => <Outlet /> })),
    handle: {
      leftNavigationPage: 'financials',
      withHamburger: true,
      restrictedTo: [
        ExactScopeName.poolPartner,
        ExactScopeName.maerskTankersUser,
        ExactScopeName.technicalManager,
      ],
    },

    children: [
      {
        index: true,
        Component: lazy(
          () => import('../../pages/pages-behind-login/financials/financials'),
        ),
      },
      {
        path: 'technical-management',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/technical-management/technical-management'
            ),
        ),
        handle: {
          restrictedTo: [ExactScopeName.technicalManager],
        },
      },
      {
        path: 'technical-management/:vessel',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/technical-management/bunker-adjustment/bunker-adjustment'
            ),
        ),
        handle: {
          restrictedTo: [ExactScopeName.technicalManager],
        },
        children: [
          {
            path: 'bunker-adjustment',
            Component: lazy(
              () =>
                import(
                  '../../pages/pages-behind-login/financials/vessel-financials/bunker-adjustment/bunker-adjustment'
                ),
            ),
            children: [
              {
                index: true,
                element: (
                  <Navigate to="bunker-adjustment-calculation" replace />
                ),
              },
              {
                path: 'bunker-adjustment-calculation',
                Component: lazy(
                  () =>
                    import(
                      '../../pages/pages-behind-login/financials/vessel-financials/bunker-adjustment/bunker-adjustment-calculation/bunker-adjustment-calculation'
                    ),
                ),
              },
              {
                path: 'trends',
                Component: lazy(
                  () =>
                    import(
                      '../../pages/pages-behind-login/financials/vessel-financials/bunker-adjustment/trends/trends'
                    ),
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'forecast/:pool',
        Component: lazy(
          () => import('../../pages/pages-behind-login/forecast/forecast'),
        ),
        handle: {
          withPools: true,
          restrictedTo: [
            ExactScopeName.poolPartner,
            ExactScopeName.maerskTankersUser,
          ],
        },
      },
      {
        path: 'earnings/:pool',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/financials/earnings/earnings'
            ),
        ),
        handle: {
          withPools: true,
          restrictedTo: [
            ExactScopeName.poolPartner,
            ExactScopeName.maerskTankersUser,
          ],
        },
        children: [
          {
            index: true,
            element: <Navigate to="compare-vessels" replace />,
          },
          {
            path: 'compare-vessels',
            Component: lazy(
              () =>
                import(
                  '../../pages/pages-behind-login/financials/earnings/components/compare-vessels-tab/compare-vessels-tab'
                ),
            ),
          },
          {
            path: 'pool-vessels',
            Component: lazy(
              () =>
                import(
                  '../../pages/pages-behind-login/financials/earnings/components/pool-vessels-tab/pool-vessels-tab'
                ),
            ),
          },
        ],
      },
      {
        path: 'reports/:pool/:reportType',
        Component: lazy(
          () => import('../../pages/pages-behind-login/reports/report/report'),
        ),
        handle: {
          withPools: true,
          restrictedTo: [
            ExactScopeName.poolPartner,
            ExactScopeName.maerskTankersUser,
          ],
        },
      },
      {
        path: 'reports/:pool',
        Component: lazy(
          () => import('../../pages/pages-behind-login/reports/reports'),
        ),
        handle: {
          withPools: true,
          restrictedTo: [
            ExactScopeName.poolPartner,
            ExactScopeName.maerskTankersUser,
          ],
        },
      },
    ],
  },
  {
    path: 'panel/financials/earnings/:pool/vessel/:vessel/partnerAxCode/:partnerAxCode',
    Component: lazy(
      () =>
        import(
          '../../pages/pages-behind-login/financials/vessel-financials/vessel-financials'
        ),
    ),
    handle: {
      disableLeftNavigation: true,
      withHamburger: true,
      restrictedTo: [
        ExactScopeName.poolPartner,
        ExactScopeName.maerskTankersUser,
      ],
    },
    children: [
      {
        path: 'payout',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/financials/vessel-financials/payout/payout'
            ),
        ),
      },
      {
        path: 'earnings',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/financials/vessel-financials/earnings/earnings'
            ),
        ),
      },
      {
        path: 'pool-points',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/financials/vessel-financials/pool-points/pool-points'
            ),
        ),
      },
      {
        path: 'scrubber-bonus',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/financials/vessel-financials/scrubber-bonus/scrubber-bonus'
            ),
        ),
      },
      {
        path: 'bunker-adjustment',
        Component: lazy(
          () =>
            import(
              '../../pages/pages-behind-login/financials/vessel-financials/bunker-adjustment/bunker-adjustment'
            ),
        ),
        children: [
          {
            index: true,
            element: <Navigate to="bunker-adjustment-calculation" replace />,
          },
          {
            path: 'bunker-adjustment-calculation',
            Component: lazy(
              () =>
                import(
                  '../../pages/pages-behind-login/financials/vessel-financials/bunker-adjustment/bunker-adjustment-calculation/bunker-adjustment-calculation'
                ),
            ),
          },
          {
            path: 'trends',
            Component: lazy(
              () =>
                import(
                  '../../pages/pages-behind-login/financials/vessel-financials/bunker-adjustment/trends/trends'
                ),
            ),
          },
        ],
      },
    ],
  },

  {
    path: 'panel/news/:pool/:id',
    Component: lazy(
      () => import('../../pages/pages-behind-login/news/single-news'),
    ),
    handle: {
      withHamburger: true,
      disableLeftNavigation: true,
    },
  },
  {
    path: 'panel/news/:pool',
    Component: lazy(() => import('../../pages/pages-behind-login/news/news')),
    handle: {
      withPools: true,
      disableLeftNavigation: true,
    },
  },
  {
    path: 'panel/calendar/:pool',
    Component: lazy(
      () => import('../../pages/pages-behind-login/calendar/calendar-page'),
    ),
    handle: {
      withPools: true,
      restrictedTo: [
        ExactScopeName.poolPartner,
        ExactScopeName.maerskTankersUser,
      ],
      disableLeftNavigation: true,
    },
  },
  {
    path: 'panel/contact',
    Component: lazy(
      () => import('../../pages/pages-behind-login/contact/contact'),
    ),
    handle: {
      withPools: false,
      disableLeftNavigation: true,
    },
  },
  {
    path: 'panel/404',
    Component: lazy(() => import('../../pages/auth/unauthorized-page')),
    handle: {},
  },
]
