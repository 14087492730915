import { CloseIcon, Typography } from '@maersktankersdigital/web-components'
import { ModalProps } from '@mui/base/Modal'
import { forwardRef } from 'react'

import { AnimatedAlert } from '~components/molecules/animated-alert/animated-alert'
import {
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalTitle,
  StyledBackdrop,
  StyledClickPoint,
  StyledConfirmModal,
  StyledModal,
  StyledSecondaryModal,
  StyledWideModal,
} from '~components/molecules/modal/modal.styles'

export type IModal = ModalProps & {
  footerButtons?: JSX.Element
  onClose?: () => void
  title?: string
  variant?: 'primary' | 'secondary' | 'confirm' | 'wide'
  modalCaption?: string
  formTitle?: string
  disableCloseIcon?: boolean
}

const StyledModalComponentVariants = {
  primary: StyledModal,
  secondary: StyledSecondaryModal,
  confirm: StyledConfirmModal,
  wide: StyledWideModal,
}

export const Modal = ({
  children,
  className,
  footerButtons,
  formTitle,
  modalCaption,
  onClose,
  open,
  title,
  variant = 'primary',
  disableCloseIcon,
  ...props
}: IModal) => {
  const StyledModalVariant = StyledModalComponentVariants[variant]

  return (
    <StyledModalVariant
      {...props}
      open={open}
      onClose={onClose}
      slots={{ backdrop: Backdrop }}
      className={className}
    >
      <>
        <ModalContent>
          {onClose && !disableCloseIcon && (
            <StyledClickPoint ariaLabel="close modal" onClick={onClose}>
              <CloseIcon />
            </StyledClickPoint>
          )}
          {(modalCaption || formTitle || title) && (
            <ModalHeader>
              {title && <ModalTitle>{title}</ModalTitle>}
              {modalCaption && (
                <Typography variant="caption" as="p">
                  {modalCaption}
                </Typography>
              )}
              {formTitle && (
                <Typography variant="label" as="p">
                  {formTitle}
                </Typography>
              )}
            </ModalHeader>
          )}
          <ModalBody>{children}</ModalBody>
        </ModalContent>
        <AnimatedAlert />
      </>
    </StyledModalVariant>
  )
}

export const Backdrop = forwardRef<
  HTMLDivElement,
  { className: string; open?: boolean }
>((props, ref) => {
  const { open, className, ...other } = props
  return <StyledBackdrop ref={ref} className={className} {...other} />
})
