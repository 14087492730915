import { Checkbox } from '@maersktankersdigital/web-components'
import { Popup } from '@mui/base/Unstable_Popup/Popup'
import { Box, Divider, Typography } from '@mui/material'
import { add, isWithinInterval } from 'date-fns'
import * as React from 'react'
import { useState } from 'react'
import { Tooltip } from '~components/tooltip'
import { usePositionListView } from '~pages/pages-behind-login/chartering/contexts/position-list-view-provider'
import { formatDate } from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/position-list-table-content/position-list-table-content'
import FavoriteToggle from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/vessel-name-content/favorite-toggle'
import NoWorldAreaContent from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/vessel-name-content/no-world-area-content'
import TradeSymbolsContent from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/vessel-name-content/trade-symbols-content'
import {
  RedeliveryPopupContent,
  StyledRouterLink,
  TradeSymbolsContentWrapper,
  VesselDetailBold,
  VesselDetails,
  VesselNameTitle,
  VesselNameWrapper,
  VesselWrapper,
} from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/vessel-name-content/vessel-name.styles'
import { PositionVesselData } from '~pages/pages-behind-login/chartering/types/position-list-types'
import { PageRoute } from '~routing/constants/page-route'
import { COLORS } from '~theme/colors'
import { capitalizeStringWords } from '~utils/capitalize-string-words'

interface Props {
  vesselData: PositionVesselData
}

const parseMetric = (metric: string): number => {
  const parsedMetric = parseInt(metric, 10)
  if (isNaN(parsedMetric)) return 0
  const roundedThousands = Math.round(parsedMetric / 1000)
  return roundedThousands
}

function VerticalDivider() {
  return (
    <Divider
      orientation="vertical"
      sx={{
        color: COLORS.primary.maerskBlue,
        borderColor: COLORS.primary.maerskBlue,
        height: '11px',
        mx: 1,
        mt: '2px',
      }}
    />
  )
}

const isEmptyObject = (obj: Record<any, any>) =>
  Object.keys(obj).length === 0 && obj.constructor === Object

function VesselNameContent({ vesselData }: Props) {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null)

  const {
    vessel: vesselName,
    dwt,
    imoClass,
    deliveryDate,
    cbm,
    redeliveryDate,
  } = vesselData
  const leaveDate = redeliveryDate ? new Date(redeliveryDate) : null
  const today = new Date()
  const ninetyDaysFromToday = add(today, { days: 90 })
  const leavingPool = leaveDate
    ? isWithinInterval(leaveDate, { start: today, end: ninetyDaysFromToday })
    : false
  const noWorldArea = vesselData.worldArea
    ? isEmptyObject(vesselData?.worldArea)
    : true
  const { settings, saveSettings } = usePositionListView()

  const renderVesselDetails = () => {
    const DWT = dwt && (
      <>
        <VerticalDivider />
        DWT{'\u00A0'}
        <VesselDetailBold>{`${parseMetric(dwt)}K`}</VesselDetailBold>
        {'\u00A0'}
      </>
    )
    const CBM = cbm && (
      <>
        <VerticalDivider />
        CBM{'\u00A0'}
        <VesselDetailBold>{`${parseMetric(cbm)}K`}</VesselDetailBold>
      </>
    )

    const IMO = imoClass && (
      <>
        <VerticalDivider />
        IMO{'\u00A0'}
        <VesselDetailBold>
          {imoClass.includes('IMO') ? imoClass.split(' ')?.[1] : imoClass}
        </VesselDetailBold>
        {'\u00A0'}
      </>
    )
    return (
      <>
        {deliveryDate ? (
          <Tooltip title="Vessel delivery date">
            <Typography
              variant="caption"
              sx={{ fontSize: '10px', whiteSpace: 'nowrap' }}
            >
              {deliveryDate}
            </Typography>
          </Tooltip>
        ) : (
          'N/A'
        )}
        {IMO} {DWT} {CBM}
      </>
    )
  }

  const handleMouseOver = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget)
  }

  const handleMouseLeave = () => {
    setAnchor(null)
  }

  const handleExportVessel = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target
    const currentExportedVessels = settings?.exportedVessels || []
    const newExportedVessels = checked
      ? [...currentExportedVessels, id]
      : currentExportedVessels.filter((vesselId) => vesselId !== id)
    saveSettings({ exportedVessels: newExportedVessels })
  }

  const isMarkedForExport = settings?.exportedVessels?.includes(
    vesselData.vesselId,
  )

  const open = Boolean(anchor) && leavingPool

  return (
    <VesselNameWrapper>
      {settings?.isExporting && (
        <Box
          sx={{
            marginLeft: '4px',
          }}
        >
          <Checkbox
            name={vesselData.vesselId}
            onChange={handleExportVessel}
            checked={isMarkedForExport}
          />
        </Box>
      )}
      <Box
        sx={{
          marginRight: '10px',
        }}
      >
        <FavoriteToggle vesselIMO={vesselData.imoNumber} />
      </Box>
      <VesselWrapper>
        <VesselNameWrapper>
          <VesselNameTitle
            anchor={anchor}
            as="div"
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
          >
            <StyledRouterLink
              to={PageRoute.VESSEL}
              params={{ vesselId: vesselData.vesselId }}
              target="_blank"
              leavingPool={leavingPool}
            >
              {capitalizeStringWords(vesselName)}
            </StyledRouterLink>
            {noWorldArea && <NoWorldAreaContent />}
          </VesselNameTitle>
        </VesselNameWrapper>
        <VesselDetails>{renderVesselDetails()}</VesselDetails>
      </VesselWrapper>
      <TradeSymbolsContentWrapper>
        <TradeSymbolsContent vesselData={vesselData} />
      </TradeSymbolsContentWrapper>
      <Popup anchor={anchor} open={open} placement="top" style={{ zIndex: 2 }}>
        <RedeliveryPopupContent>
          {`Re-delivery by ${formatDate(redeliveryDate)}`}
        </RedeliveryPopupContent>
      </Popup>
    </VesselNameWrapper>
  )
}

export default VesselNameContent
