import {
  Typography,
  theme,
  typographyStyles,
} from '@maersktankersdigital/web-components'
import { Popup } from '@mui/base/Unstable_Popup/Popup'
import { format, parseISO } from 'date-fns'
import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { PopupContentWrapper } from '~pages/pages-behind-login/chartering/chartering.styles'

interface Props {
  aisData: any
  children: React.ReactNode
}

const Coordinates = styled.div`
  ${typographyStyles.paragraphBold2}

  ::after {
    content: ' lat, long';
    ${typographyStyles.paragraph2}
    font-size: ${theme.FONTS.sizes.xxs};
    font-weight: ${theme.FONTS.weights.normal};
  }
`

function AISContent({ aisData, children }: Props) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const open = Boolean(anchor)

  const handleMouseOver = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget)
  }

  const handleMouseLeave = () => {
    setAnchor(null)
  }

  return (
    <div onMouseLeave={handleMouseLeave}>
      <div onMouseOver={handleMouseOver}>
        <Typography variant="paragraphBold2" as="span">
          {children}
        </Typography>
      </div>
      <Popup open={open} anchor={anchor}>
        <PopupContentWrapper>
          <Typography variant="label">ETA BASED ON AIS</Typography>

          <Typography variant="menu">COORDINATES</Typography>
          <Coordinates>
            {`
                ${aisData.latitude.toFixed(2)}°,
                ${aisData.longitude.toFixed(2)}°
                
            `}
          </Coordinates>
          <Typography variant="menu">POSITION LAST UPDATED</Typography>
          <Typography variant="paragraphBold2">
            {format(parseISO(aisData.aisPositionReceived), 'dd/MM/yyyy HH:mm')}
          </Typography>
        </PopupContentWrapper>
      </Popup>
    </div>
  )
}

export default AISContent
