import { format } from 'date-fns'

import { RouterLink } from '~components/atoms/router-link'
import {
  HeaderItem,
  HeaderItemDate,
  HeaderItemDateSeparator,
  HeaderItemValue,
  HeaderItemVoyageNoValue,
  VoyageTrackerHeaderWrapper,
} from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/voyage-tracker-position-list/voyage-tracker-position-list.styles'
import { PositionListVoyage } from '~pages/pages-behind-login/chartering/types/position-list-types'

interface Props {
  currentVoyage: PositionListVoyage
}

function VoyageTrackerHeader({ currentVoyage }: Props) {
  const { voyageNumber, operator, lastUpdateGmt } = currentVoyage

  return (
    <VoyageTrackerHeaderWrapper>
      <HeaderItem>
        Voyage number:{' '}
        <HeaderItemVoyageNoValue>
          <RouterLink
            to={`https://veslink.com/#operations/voyages/${currentVoyage.vesselCode}/${currentVoyage.voyageNumber}`}
            target="_blank"
            externalLink
          >
            {voyageNumber}
          </RouterLink>
        </HeaderItemVoyageNoValue>
      </HeaderItem>
      <HeaderItemDate>
        Last update GMT:{' '}
        <HeaderItemValue>
          {format(new Date(lastUpdateGmt || ''), 'dd-MM')}
        </HeaderItemValue>{' '}
        <HeaderItemDateSeparator />
        <HeaderItemValue>
          {format(new Date(lastUpdateGmt || ''), 'HH:mm')}
        </HeaderItemValue>
      </HeaderItemDate>
      <HeaderItem>
        Operator: <HeaderItemValue>{operator}</HeaderItemValue>
      </HeaderItem>
      <HeaderItem>
        Actual TCE:{' '}
        <HeaderItemValue>{currentVoyage.actualTce ?? 0}</HeaderItemValue>
      </HeaderItem>
      <HeaderItem>
        Estimated TCE:{' '}
        <HeaderItemValue>{currentVoyage.estimatedTce ?? 0}</HeaderItemValue>
      </HeaderItem>
      <HeaderItem>
        Delta TCE:{' '}
        <HeaderItemValue>{currentVoyage.actualTceDelta ?? 0}</HeaderItemValue>
      </HeaderItem>
      <HeaderItem>
        Delta days:{' '}
        <HeaderItemValue>
          {currentVoyage.actualEstimatedDaysDelta ?? 0}
        </HeaderItemValue>
      </HeaderItem>
    </VoyageTrackerHeaderWrapper>
  )
}

export default VoyageTrackerHeader
