import { Typography } from '@mui/material'
import { Type } from '../../cargo-modal/add-cargo-modal'
import { ValueWithTooltip } from './value-with-tooltip'

interface RenderQuantityAndPeriodProps {
  quantity?: number | string
  quantityType?: string
  periodDetails?: string
  periodTcOut?: number
  periodTcOutType?: string
  tradingType?: Type
}

export function QuantityAndPeriodRenderer({
  quantity,
  quantityType,
  periodDetails,
  periodTcOut,
  periodTcOutType,
  tradingType,
}: RenderQuantityAndPeriodProps) {
  if (tradingType?.toLowerCase() === 'spot' && (quantity || quantityType)) {
    return (
      <Typography variant="caption">{`${quantity || '-'} ${quantityType || ''}`}</Typography>
    )
  }
  if (
    tradingType?.toLowerCase() === 't/c' &&
    (periodTcOut || periodTcOutType)
  ) {
    return (
      <ValueWithTooltip
        value={`${periodTcOut || '-'} ${periodTcOutType?.toLowerCase() || ''}`}
        tooltipText={periodDetails}
      />
    )
  }
  return <></>
}
