import PageHeader from '../v2/components/page-header'

interface Props {
  myListsButtonRef?: React.RefObject<HTMLButtonElement>
  setShowMyLists?: React.Dispatch<React.SetStateAction<boolean>>
}

function PositionListHeader({ setShowMyLists, myListsButtonRef }: Props) {
  return (
    <PageHeader
      myListsButtonRef={myListsButtonRef}
      setShowMyLists={setShowMyLists}
    />
  )
}

export default PositionListHeader
