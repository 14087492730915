import { ClockIcon } from '@maersktankersdigital/web-components'
import { Box, MenuItem, Select, TableCell } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { Tooltip } from '~components/tooltip'
import { COLORS } from '~theme/colors'
import { typography } from '~theme/global'
import {
  CargoStatus,
  quickEditStatusOptions,
} from '../../../content/select-n-autocomplete-options'
import { ValueWithTooltip } from './value-with-tooltip'

const showSubsDueTimeStatuses = [CargoStatus.OnSubs]
const showStatusSelect = [CargoStatus.OnSubs]

export function StatusCell({
  status,
  subsDueTime,
  onChange,
}: {
  status: CargoStatus
  subsDueTime?: string | undefined
  onChange: (status: CargoStatus) => void
}) {
  const tooltipText = getTooltipText(subsDueTime, status)
  return (
    <TableCell
      sx={{
        background: getBackgroundColor(status),
      }}
    >
      {!showStatusSelect.includes(status) ? (
        <ValueWithTooltip value={status} tooltipText={tooltipText} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Select
            sx={{
              '& .MuiSelect-select': {
                py: 1,
                px: 0,
                ...typography.caption,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            value={status}
            onChange={(e) => {
              onChange(e.target.value as CargoStatus)
            }}
            MenuProps={{
              BackdropProps: {
                style: {
                  backgroundColor: 'transparent',
                },
              },
            }}
          >
            {quickEditStatusOptions.map((item) => (
              <MenuItem key={item} value={item} sx={typography.caption}>
                {item}
              </MenuItem>
            ))}
          </Select>
          {subsDueTime && showSubsDueTimeStatuses.includes(status) && (
            <Tooltip title={tooltipText}>
              <ClockIcon
                color={COLORS.secondary.darkBlue}
                width="16px"
                height="16px"
              />
            </Tooltip>
          )}
        </Box>
      )}
    </TableCell>
  )
}

function getBackgroundColor(status: CargoStatus): string {
  switch (status) {
    case CargoStatus.Open:
      return COLORS.citrus.tint1
    case CargoStatus.Poss:
      return COLORS.orange.tint3
    case CargoStatus.OnSubs:
    case CargoStatus.Fixed:
    case CargoStatus.Withdrawn:
      return COLORS.orange.tint1
    case CargoStatus.Failed:
      return COLORS.red.light
    default:
      return 'transparent'
  }
}

function getTooltipText(
  subsDueTime?: string,
  status?: CargoStatus,
): string | undefined {
  if (!subsDueTime || !showSubsDueTimeStatuses.includes(status!))
    return undefined
  return `Subs due time: ${format(parseISO(subsDueTime), 'dd-MM HH:mm')}`
}
