import {
  PageWrapper,
  Typography,
  useBreakpoint,
} from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import { useLocation, useParams } from 'react-router-dom'
import { InfoLabel } from '~components/atoms/info-label/info-label'
import { useGetVessel } from '~hooks/queries/vessels/use-get-vessel'
import { useNavAndFooterConstants } from '~hooks/use-nav-and-footer-constants'
import { DeliveryLabel } from '~pages/pages-behind-login/vessel-page/delivery-label/delivery-label'
import { RedeliveryLabel } from '~pages/pages-behind-login/vessel-page/redelivery-label/redelivery-label'
import { HeaderActions } from '~pages/pages-behind-login/vessel-page/vessel-header/header-actions/header-actions'
import { HeaderDetails } from '~pages/pages-behind-login/vessel-page/vessel-header/header-details/header-details'
import {
  getVesselPageSubHeaderData,
  VESSEL_PAGE_CONFIG,
  VESSEL_PAGE_HEADER_DATA,
} from '~pages/pages-behind-login/vessel-page/vessel-page-data'
import { useVesselPageState } from '~pages/pages-behind-login/vessel-page/vessel-page-provider'
import { COLORS } from '~theme/colors'
import { Z_INDEX } from '~types/z-index'
import { getActiveRoute } from '~utils/get-active-route'
import MobileHeader from './mobile-header/mobile-header'
import { StyledHeadlineAndActionsContainer } from './vessel-header.styles'

export enum TrackFieldValue {
  EARNINGS_SIMULATION = 'earnings-simulation',
  NORMAL = 'normal',
  COMMERCIAL_MANAGEMENT = 'commercial-management',
  ONBOARDING = 'onboarding',
}

export function VesselHeader() {
  const { vesselId } = useParams()
  const state = useVesselPageState()
  const routeParams = useParams()
  const { pathname } = useLocation()
  const [, activeRoute] = getActiveRoute(pathname, routeParams)
  const { isMobileSize } = useBreakpoint()
  const { topNavigationHeight } = useNavAndFooterConstants()

  const subHeaderData = activeRoute
    ? getVesselPageSubHeaderData()[activeRoute]
    : undefined

  const pageConfig = activeRoute && {
    // @ts-ignore
    header: VESSEL_PAGE_HEADER_DATA[activeRoute],
    subHeader: subHeaderData,
    // @ts-ignore
    page: VESSEL_PAGE_CONFIG[activeRoute],
  }

  const {
    data: vessel,
    isLoading: isLoadingVesselData,
    error: errorVesselData,
  } = useGetVessel({ vesselId })

  const isCommercialManagement =
    vessel?.status === TrackFieldValue.COMMERCIAL_MANAGEMENT

  return (
    <>
      {isMobileSize && vessel && <MobileHeader vessel={vessel} />}
      <Box
        sx={{
          position: isMobileSize ? 'relative' : 'sticky',
          top: isMobileSize ? 0 : topNavigationHeight,
          left: 0,
          background: COLORS.greys.ultraLight,
          boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
          zIndex: Z_INDEX.PageHeader,
        }}
      >
        {vessel && (
          <Box
            component={PageWrapper}
            sx={{
              py: 4,
              px: '52px !important',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: 3,
                justifyContent: 'space-between',
                flexWrap: 'wrap',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: { xs: 2, xl: 7 },
                  flexWrap: 'wrap',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {!isMobileSize && (
                    <StyledHeadlineAndActionsContainer>
                      <Typography variant="headline1">
                        {vessel.vesselName}
                      </Typography>
                      <HeaderActions vesselData={vessel} />
                    </StyledHeadlineAndActionsContainer>
                  )}
                  {isCommercialManagement && (
                    <Typography variant="menu">
                      Commercial Management
                    </Typography>
                  )}
                </Box>

                <HeaderDetails vessel={vessel} />
              </Box>
              {pageConfig?.header?.buttonComponentFn?.(state)}
            </Box>

            <Box sx={{ display: 'flex', gap: 2, mt: 0, flexWrap: 'wrap' }}>
              <DeliveryLabel />
              <RedeliveryLabel />
              {state.isVesselArchived && (
                <InfoLabel text="archived" variant="VesselDashboard" />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </>
  )
}
