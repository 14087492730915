import { Select } from '@maersktankersdigital/web-components'
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { COLORS } from '~theme/colors'
import {
  CargoStatus,
  statusOptions,
} from '../content/select-n-autocomplete-options'
import { FormInput, Type } from './cargo-modal/add-cargo-modal'
import {
  CargoGradeAutocomplete,
  PicAutocomplete,
  PoolAutocomplete,
} from './fields'
import { DateInputField } from './fields/date-input'
import { Laycan } from './fields/laycan'

export interface FieldsGroupProps {
  updateFormInput: (arg0: FormInput) => void
  formInput: FormInput
  missingFields?: string[]
  disabledFields?: string[]
}

export const rowStyle = {
  display: 'flex',
  rowGap: 5,
  columnGap: 7,
  flexWrap: 'wrap',
}

export function SharedFields(props: FieldsGroupProps) {
  const { updateFormInput, formInput, missingFields, disabledFields } = props

  return (
    <>
      <Grid size={{ xs: 12, lg: 6, xxxl: 2 }}>
        <Select
          label="status *"
          name="status"
          options={statusOptions.map((name) => ({
            label: name,
            value: name,
          }))}
          value={formInput.status}
          onChange={(e, value) =>
            updateFormInput({ status: value as CargoStatus })
          }
          slotProps={{ popper: { style: { zIndex: 9999 } } }}
          // @ts-ignore
          error={missingFields?.includes('status')}
          disabled={disabledFields?.includes('status')}
        />
      </Grid>

      <Grid size={{ xs: 12, md: 6, xxxl: 2 }}>
        <Box>
          <Typography
            variant="label"
            color={
              disabledFields?.includes('tradingType')
                ? COLORS.greys.mid
                : COLORS.secondary.darkBlue
            }
          >
            Type *
          </Typography>
          <RadioGroup
            onChange={(e, value) =>
              updateFormInput({ tradingType: value as Type })
            }
            sx={{ flexDirection: 'row' }}
            value={formInput.tradingType?.toUpperCase()}
          >
            <FormControlLabel
              control={
                <Radio
                  value="SPOT"
                  disabled={disabledFields?.includes('tradingType')}
                />
              }
              label="SPOT"
            />
            <FormControlLabel
              control={
                <Radio
                  value="T/C"
                  disabled={disabledFields?.includes('tradingType')}
                />
              }
              label="T/C"
            />
          </RadioGroup>
          {missingFields?.includes('tradingType') && <MissingValueHelperText />}
        </Box>
      </Grid>

      <Grid size={{ xs: 12, md: 6, xxxl: 2 }}>
        <Box>
          <Typography
            variant="label"
            color={
              disabledFields?.includes('privateAndConfidential')
                ? COLORS.greys.mid
                : COLORS.secondary.darkBlue
            }
          >
            Private & confidential? *
          </Typography>
          <RadioGroup
            onChange={(e, value) =>
              updateFormInput({ privateAndConfidential: value === 'Yes' })
            }
            sx={{ flexDirection: 'row' }}
            value={formInput.privateAndConfidential ? 'Yes' : 'No'}
          >
            <FormControlLabel
              control={
                <Radio
                  value="Yes"
                  disabled={disabledFields?.includes('privateAndConfidential')}
                />
              }
              label="Yes"
            />
            <FormControlLabel
              control={
                <Radio
                  value="No"
                  disabled={disabledFields?.includes('privateAndConfidential')}
                />
              }
              label="No"
            />
          </RadioGroup>
          {missingFields?.includes('privateAndConfidential') && (
            <MissingValueHelperText />
          )}
        </Box>
      </Grid>

      <Grid size={{ xs: 12, md: 6, xxxl: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="label"
            color={
              disabledFields?.includes('dateQuoted')
                ? COLORS.greys.mid
                : COLORS.secondary.darkBlue
            }
          >
            Date quoted *
          </Typography>
          <DateInputField
            onChangeHandler={(value) => updateFormInput({ dateQuoted: value })}
            dateTime={formInput.dateQuoted}
            hasError={missingFields?.includes('dateQuoted')}
            disabled={disabledFields?.includes('dateQuoted')}
          />
        </Box>
      </Grid>

      <Grid size={{ xs: 12, md: 6, xxxl: 2 }}>
        <PicAutocomplete {...props} />
      </Grid>

      <Grid size={{ xs: 12, md: 6, xxxl: 2 }}>
        <PoolAutocomplete {...props} />
      </Grid>
      <Grid size={{ xs: 12, md: 6, xxxl: 2 }}>
        <Laycan {...props} />
      </Grid>
      <Grid size={{ xs: 12, md: 6, xxxl: 2 }}>
        <CargoGradeAutocomplete {...props} />
      </Grid>
    </>
  )
}

function MissingValueHelperText() {
  return (
    <Typography
      variant="graphCaption"
      sx={{ color: COLORS.feedback.red.error, mx: 3 }}
    >
      please select value
    </Typography>
  )
}
