import { useEffect, useRef, useState } from 'react'
import { useGetCommercialOperator } from '~hooks/queries/cargo/use-get-commercial-operator'
import { minCharsForAutocomplete, toBeNamedText } from '../../constants'
import { FieldsGroupProps } from '../shared-fields'
import { SingleItemAutocomplete } from './autocomplete/base-components/single-item-autocomplete'

const vesselNameBlacklist = [toBeNamedText]

export function CommercialOperator({
  formInput,
  updateFormInput,
  missingFields,
  disabledFields,
}: FieldsGroupProps) {
  const [inputValue, setInputValue] = useState('')
  const [isInitialLoadWithVessel, setIsInitialLoadWithVessel] = useState(true)
  const isInitialRender = useRef(true)

  const { data } = useGetCommercialOperator({
    commercialOperatorName: inputValue,
    vesselName: formInput.vessel,
    disabled:
      isInitialLoadWithVessel ||
      (!formInput.vessel && inputValue.length < minCharsForAutocomplete) ||
      (!!formInput.vessel && vesselNameBlacklist.includes(formInput.vessel)),
  })

  useEffect(() => {
    setIsInitialLoadWithVessel(!!formInput.vessel)
  }, [])

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      return
    }

    if (formInput.vessel) {
      setIsInitialLoadWithVessel(false)
    }

    updateFormInput({ commercialOperator: undefined })
  }, [formInput.vessel])

  useEffect(() => {
    if (data?.length === 1 && !formInput.commercialOperator) {
      updateFormInput({ commercialOperator: data[0] })
    }
  }, [data])

  return (
    <SingleItemAutocomplete
      label="Commercial Operator"
      input={formInput.commercialOperator}
      setInputValue={setInputValue}
      onChangeHandler={(e, value) =>
        updateFormInput({ commercialOperator: value || undefined })
      }
      options={data || []}
      hasError={missingFields?.includes('commercialOperator')}
      disabled={disabledFields?.includes('commercialOperator')}
    />
  )
}
