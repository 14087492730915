import { useEffect, useRef, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom'
import { AnimatedAlert } from '~components/molecules/animated-alert/animated-alert'
import AnimatedAlertProvider from '~components/molecules/animated-alert/animated-alert-provider'
import AutoCompleteInputProvider from '~components/molecules/auto-complete-input/auto-complete-input-provider'
import { PageRoute } from '~routing/constants/page-route'

type OutletContext = {
  myListsButtonRef: React.RefObject<HTMLButtonElement>
  setShowMyLists: React.Dispatch<React.SetStateAction<boolean>>
  showMyLists: boolean
}

export default function Chartering() {
  const [showMyLists, setShowMyLists] = useState(false)
  const myListsButtonRef = useRef<HTMLButtonElement>(null)
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname === `/${PageRoute.CHARTERING}`) {
      navigate(`${PageRoute.POSITION_LIST}`)
    }
  }, [location])

  return (
    <HelmetProvider>
      <AutoCompleteInputProvider>
        <AnimatedAlertProvider>
          <Outlet
            context={{
              showMyLists,
              setShowMyLists,
              myListsButtonRef,
            }}
          />
          <AnimatedAlert />
        </AnimatedAlertProvider>
      </AutoCompleteInputProvider>
    </HelmetProvider>
  )
}

export function useMyLists() {
  return useOutletContext<OutletContext>()
}
