import { Button, TextButton } from '@maersktankersdigital/web-components'
import { Box } from '@mui/material'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { mutate } from 'swr'

import {
  ILastThreeCargosPayload,
  ILastThreeCargosResponse,
  userUpdateAction,
} from '~api/position-list/update'
import { ApiRoutes } from '~api/routes'
import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import ConfirmModal from '~components/molecules/modal/confirm-modal/confirm-modal'
import { Modal } from '~components/molecules/modal/modal'
import {
  StyledInput,
  StyledTextArea,
} from '~components/molecules/modal/modal.styles'
import {
  PrimaryButtonsWrapper,
  StyledModalFooter,
} from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/l3ccontent/l3ccontent.styles'
import { LastThreeCargoes } from '~pages/pages-behind-login/chartering/types/position-list-types'
import { Voyage } from '~types/itinerary.types'

interface Props {
  editModalOpen: boolean
  setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  vesselId: string
  voyages?: Voyage[]
  internalLastThreeCargoes?: LastThreeCargoes
}
const initialL3C = ' / / '

function EditCargoesModal({
  editModalOpen,
  setEditModalOpen,
  vesselId,
  voyages,
  internalLastThreeCargoes,
}: Props) {
  const [nextL3CComment, setNextL3CComment] = useState('')
  const [nextL3C, setNextL3C] = useState<string | undefined>('')
  const [error, setError] = useState('')
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [currentAction, setCurrentAction] = useState<'SAVE' | 'DELETE'>()
  const confirmModalText =
    currentAction === 'SAVE'
      ? 'By entering this information, you are actively overwriting existing IMOS information.'
      : 'Do you really wish to delete the manually set last three cargoes?'

  const { setStatus } = useAnimatedAlert()

  useEffect(() => {
    setNextL3C(internalLastThreeCargoes?.lastThreeCargoes || initialL3C)
  }, [internalLastThreeCargoes?.lastThreeCargoes])

  const handleCloseEditModal = () => {
    setError('')
    setNextL3CComment('')
    setNextL3C('')
    setEditModalOpen(false)
  }

  const handleSaveL3C = () => {
    if (!nextL3C) {
      return
    }
    if (
      // Check if there's at least two / since N/A is allowed there might be more
      // @ts-ignore
      nextL3C.match(/\//g).length >= 2
    ) {
      const parsedNextL3C = nextL3C.toUpperCase().trim()

      setNextL3C(parsedNextL3C)
    } else {
      setOpenConfirmationModal(false)
      setError('Please enter three cargos separated by / ')
      return
    }
    setCurrentAction('SAVE')
    setOpenConfirmationModal(true)
  }

  const saveL3CToDB = async () => {
    setOpenConfirmationModal(false)
    const payload = {
      lastThreeCargoes: nextL3C,
      comment: nextL3CComment || null,
    }

    await userUpdateAction<ILastThreeCargosPayload, ILastThreeCargosResponse>(
      payload,
      { vesselId },
    )
      .then((response) => {
        if (response.statusCode) {
          throw new Error()
        }
        handleCloseEditModal()
        setStatus(
          'You have successfully updated the last three cargoes',
          5000,
          'success',
        )
        mutate(ApiRoutes.POSITION_LIST.ALL)
      })
      .catch((error: Error) => {
        console.error(error)
        setStatus(
          'Something went wrong when updating the last three cargoes',
          5000,
          'error',
        )
      })
  }

  const handleDeleteL3C = () => {
    setCurrentAction('DELETE')
    setOpenConfirmationModal(true)
  }

  const handleDeleteL3CRequest = async () => {
    setOpenConfirmationModal(false)
    await userUpdateAction<ILastThreeCargosPayload, ILastThreeCargosResponse>(
      { lastThreeCargoes: null, comment: null },
      { vesselId },
    )
      .then((response) => {
        if (response.statusCode) {
          throw new Error()
        }
        handleCloseEditModal()
        setStatus(
          'You have successfully deleted the last three cargoes',
          5000,
          'success',
        )
        mutate(ApiRoutes.POSITION_LIST.ALL)
      })
      .catch((error: Error) => {
        console.error(error)
        setStatus(
          'Something went wrong when deleting the last three cargoes ',
          5000,
          'error',
        )
      })
  }

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNextL3CComment(e.target.value)
  }

  const handleChangeL3C = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNextL3C(e.target.value)
  }

  return (
    <Modal open={editModalOpen} onClose={handleCloseEditModal} title="Edit L3C">
      <>
        <Box sx={{ mb: 5 }}>
          <StyledInput
            label="Current IMOS Last three cargos"
            name="currentL3C"
            onChange={() => null}
            value={voyages?.map((voyage) => voyage.productCodes).join(' /')}
          />
        </Box>
        <Box sx={{ mb: 5 }}>
          <StyledInput
            label="New Last Three Cargos"
            name="nextL3c"
            value={nextL3C}
            onChange={handleChangeL3C}
            error={error}
          />
        </Box>

        <StyledTextArea
          name="L3CComment"
          label="Comment (optional)"
          value={nextL3CComment}
          onChange={handleCommentChange}
        />
        <StyledModalFooter>
          {internalLastThreeCargoes?.lastThreeCargoes ? (
            <TextButton variant="warning" onClick={handleDeleteL3C}>
              Delete new L3C
            </TextButton>
          ) : (
            <div />
          )}
          <PrimaryButtonsWrapper>
            <TextButton onClick={handleCloseEditModal}>Cancel</TextButton>
            <Button
              variant="secondary"
              disabled={nextL3C === initialL3C}
              onClick={handleSaveL3C}
            >
              Save
            </Button>
          </PrimaryButtonsWrapper>
        </StyledModalFooter>

        <ConfirmModal
          content={confirmModalText}
          isVisible={openConfirmationModal}
          onConfirm={
            currentAction === 'SAVE' ? saveL3CToDB : handleDeleteL3CRequest
          }
          onClose={() => setOpenConfirmationModal(false)}
        />
      </>
    </Modal>
  )
}

export default EditCargoesModal
