import {
  Button,
  CloseIcon,
  ImportIcon,
} from '@maersktankersdigital/web-components'
import { Icon } from '~components/atoms/icon'
import Paragraph from '~components/atoms/typography/paragraph/paragraph'
import { CopyDashboardToClipboardButton } from '~components/organisms/dashboard/copy-dashboard-to-clipboard-button/copy-dashboard-to-clipboard-button'
import PoolPointsPageProvider from '~pages/pages-behind-login/onboarded-vessel/pool-points-page/pool-points-page-provider'
import { VesselPageState } from '~pages/pages-behind-login/vessel-page/vessel-page-provider'
import { COLORS } from '~theme/colors'
import { VesselStatus } from '~types/vessel.types'

import { Colors } from '~theme/deprecated-vt/deprecated-vt-theme'
import { PageRoute } from '../../../routes/routes-behind-login/vt-routing/constants/page-route'
import { PoolPointsPageHeader } from './sub-components/pool-points-page-header'
import { PoolPointsSumUp } from './sub-components/pool-points-sum-up'
import { PortEssentialDisclaimer } from './sub-components/port-essential-disclaimer'

export const VESSEL_PAGE_CONFIG = {
  [PageRoute.POOL_POINTS]: {
    PageProvider: PoolPointsPageProvider,
  },
}

export const VESSEL_PAGE_HEADER_DATA = {
  [PageRoute.VESSEL_DASHBOARD]: {
    buttonComponentFn: () => <CopyDashboardToClipboardButton />,
  },
  [PageRoute.OPS_DASHBOARD]: {
    buttonComponentFn: () => <CopyDashboardToClipboardButton />,
  },
  [PageRoute.DOCUMENTS]: {
    buttonComponentFn: (state: VesselPageState) => (
      <Button
        variant="secondary"
        icon={
          state.isBulkSelectActive ? (
            <CloseIcon size={16} />
          ) : (
            <ImportIcon size={16} />
          )
        }
        onClick={() => state.setIsBulkSelectActive(!state.isBulkSelectActive)}
      >
        <Paragraph>
          {state.isBulkSelectActive ? 'Cancel' : 'Select for download'}
        </Paragraph>
      </Button>
    ),
  },
  [PageRoute.QUESTIONNAIRE]: {
    buttonComponentFn: (state: VesselPageState) =>
      state.vessel?.status === VesselStatus.INCOMING && (
        <Button
          variant="secondary"
          onClick={() => state.setRerunEarningsSimulator(true)}
        >
          Run Earnings Simulator
        </Button>
      ),
  },
  [PageRoute.POOL_POINTS]: {
    buttonComponentFn: (state: VesselPageState) => (
      <Button
        variant="secondary"
        icon={<Icon name="copy" size={24} fill={Colors.blues.mid} />}
        onClick={() => {
          state.setExportToExcel(true)
        }}
      >
        <Paragraph color={COLORS.secondary.midBlue}>
          Export data to Excel
        </Paragraph>
      </Button>
    ),
  },
}

type SubHeaderData = {
  header?: {
    componentFn?: () => JSX.Element
    copy?: string
  }
  customComponent?: () => JSX.Element
}

type VesselPageSubHeaderData = {
  [key in PageRoute]?: SubHeaderData
}

export const getVesselPageSubHeaderData = (): VesselPageSubHeaderData => ({
  [PageRoute.VESSEL_DASHBOARD]: {},
  [PageRoute.PARTNER_DASHBOARD]: {},
  [PageRoute.OPS_DASHBOARD]: {},
  [PageRoute.BASIC_VESSEL_DATA]: {
    header: {
      copy: 'Basic Vessel Data',
    },
  },
  [PageRoute.DOCUMENTS]: {
    header: {
      copy: 'Documents',
    },
  },
  [PageRoute.POOL_RESTRICTIONS]: {
    header: {
      copy: 'Restrictions',
    },
  },
  [PageRoute.QUESTIONNAIRE]: {
    header: {
      copy: 'Questionnaire',
    },
  },
  [PageRoute.VOYAGES]: {
    header: {
      copy: 'Voyages',
    },
  },
  [PageRoute.VETTING]: {
    header: {
      copy: 'Vetting',
    },
  },
  [PageRoute.PORT_ESSENTIALS]: {
    header: {
      copy: 'Port Essentials',
    },
    customComponent: () => <PortEssentialDisclaimer />,
  },
  [PageRoute.POOL_POINTS]: {
    header: {
      componentFn: () => <PoolPointsPageHeader />,
    },
    customComponent: () => <PoolPointsSumUp />,
  },
})
