import CommentField, {
  ApiConfig,
} from '~components/molecules/comment-field/comment-field'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { IComment } from '~pages/pages-behind-login/chartering/types/position-list-types'

interface Props {
  apiConfig: ApiConfig
  canEdit?: boolean
  latestComment?: IComment
  width: number
}

function PositionListCommentField(props: Props) {
  const isPositionListUser = useHasScope(ExactScopeName.positionListUpdate)

  return <CommentField {...props} canEdit={isPositionListUser} />
}

export default PositionListCommentField
