import { Box } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { FieldsGroupProps, SharedFields } from './shared-fields'
import { SpotFields } from './spot-fields'
import { TcFields } from './tc-fields'

export function CargoForm(props: FieldsGroupProps) {
  const { formInput } = props

  return (
    <Box sx={{ mb: 6 }}>
      <Grid container spacing={4}>
        <SharedFields {...props} />
        {formInput.tradingType === 'T/C' ? (
          <TcFields {...props} />
        ) : (
          <SpotFields {...props} />
        )}
      </Grid>
    </Box>
  )
}
