import { useState, useEffect, MouseEvent } from 'react'
import {
  Box,
  Button,
  Popover,
  TextField,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Typography,
} from '@mui/material'
import {
  FileIcon,
  DeleteIcon,
  FileCopyIcon,
} from '@maersktankersdigital/web-components'
import { useSearchParams, useLocation } from 'react-router-dom'
import { usePatchMeVT } from '~hooks/queries/me/use-patch-me-vt'
import { useGetMe } from '~hooks/queries/me/use-get-me'

const SaveLists = ({
  setVesselIdsToExport,
}: {
  setVesselIdsToExport: (v: string[]) => void
}) => {
  const location = useLocation()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [newListName, setNewListName] = useState<string>('')
  const [searchParams, setSearchParams] = useSearchParams()
  const { mutateAsync: updateUserSettings } = usePatchMeVT()
  const { data: myData } = useGetMe()

  const positionListV2SavedLists = myData?.settings?.positionList?.v2Lists || []

  useEffect(() => {
    const positionListV2LastViewedList =
      myData?.settings?.positionList?.v2LastViewed
    const loadLast = Boolean(searchParams.get('loadLast'))
    searchParams.delete('loadLast')
    setSearchParams(searchParams)
    if (positionListV2LastViewedList && loadLast) {
      const initialFilter = lists.find(
        (x) => x.name === positionListV2LastViewedList,
      )?.filter
      if (initialFilter) {
        setSearchParams(initialFilter)
        setActiveList(positionListV2LastViewedList)
      }
    } else if (
      positionListV2SavedLists
        .map((l) => l.filter)
        .includes(searchParams.toString())
    ) {
      const listName = positionListV2SavedLists.find(
        (l) => l.filter === searchParams.toString(),
      )?.name
      if (listName) {
        setActiveList(listName)
      }
    }
  }, [searchParams])

  const [activeList, setActiveList] = useState<string>('')
  useEffect(() => {
    updateUserSettings({
      settings: {
        positionList: {
          v2LastViewed: activeList,
        },
      },
    })
  }, [activeList])

  const defaultLists = [
    {
      timestamp: 2,
      name: 'Default',
      filter:
        '?ShowBallastDuplicates=Show+Ballast+Duplicates&sort=Open+Date&order=desc&columns=Open+Date&columns=Open+Port&columns=L3C&columns=Comments&columns=Next+Dry+Dock&columns=Last+SIRE&columns=DWT&columns=CBM&columns=ICE&columns=IGS&columns=Year+Built&columns=Flag&columns=Operator&columns=Scrubber&columns=IMO',
    },
    {
      timestamp: 1,
      name: 'Default ETA',
      filter:
        '?ShowBallastDuplicates=Show+Ballast+Duplicates&sort=Open+Date&order=desc&columns=Open+Date&columns=Open+Port&columns=L3C&columns=Comments&columns=ETA+Gibraltar&columns=ETA+Houston&columns=ETA+Amsterdam&columns=ETA+Singapore&columns=ETA+Yanbu&columns=ETA+Yosu&columns=ETA+Fujairah&columns=ETA+Sikka&columns=ETA+Malta&columns=Level+3&columns=Level+2&columns=Level+1',
    },
    {
      timestamp: 0,
      name: 'Default World Area',
      filter:
        '?ShowBallastDuplicates=Show+Ballast+Duplicates&sort=Open+Date&order=desc&ShowWorldAreaHeaders=Show+World+Area+Headers&columns=Open+Date&columns=Open+Port&columns=L3C&columns=Comments&columns=Level+1&columns=Level+2&columns=Level+3&columns=ETA+Houston&columns=ETA+Gibraltar&columns=ETA+Amsterdam&columns=ETA+Singapore&columns=ETA+Yanbu&columns=ETA+Yosu&columns=ETA+Fujairah&columns=ETA+Sikka&columns=ETA+Malta&columns=Next+Dry+Dock&columns=US+COC&columns=Year+Built&columns=IMO&columns=ICE',
    },
  ]

  const lists = [...positionListV2SavedLists, ...defaultLists]

  const saveList = async () => {
    await updateUserSettings({
      settings: {
        positionList: {
          v2Lists: [
            ...positionListV2SavedLists.filter((x) => x.name !== newListName),
            {
              name: newListName,
              filter: searchParams.toString(),
              timestamp: Date.now(),
            },
          ],
        },
      },
    })
    setActiveList(newListName)
  }

  const activeListUpdateable = () => {
    if (!activeList) return false
    const currentParams = searchParams.toString()
    const activeListObj = positionListV2SavedLists.find(
      (x) => x.name === activeList,
    )
    if (!activeListObj) return false
    const { filter } = activeListObj
    return currentParams !== filter
  }
  const updateActiveList = async () => {
    await updateUserSettings({
      settings: {
        positionList: {
          v2Lists: [
            ...positionListV2SavedLists.filter((x) => x.name !== activeList),
            {
              name: activeList,
              filter: searchParams.toString(),
              timestamp: Date.now(),
            },
          ],
        },
      },
    })
  }

  const deleteList = async (name: string) => {
    await updateUserSettings({
      settings: {
        positionList: {
          v2Lists: positionListV2SavedLists.filter((x) => x.name !== name),
        },
      },
    })
    if (activeList === name) {
      setActiveList('')
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const copyLink = async () => {
    navigator.clipboard.writeText(
      `${window.location.origin}${location.pathname}${location.search}`,
    )
    setShareText('Copied!')
    await new Promise((r) => setTimeout(r, 2000))
    setShareText('Share Link to Current List')
  }
  const [shareText, setShareText] = useState('Share Link to Current List')

  return (
    <>
      {/* {checkedInitialFilter && ( */}
      <Button
        key="save-lists-btn"
        variant="contained"
        onClick={handleClick}
        endIcon={<FileIcon />}
      >
        <Typography
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: { xs: '8ch', md: '16ch', xl: '20ch' },
          }}
          variant="inherit"
        >
          My Lists {activeList ? `- ${activeList}` : ''}
        </Typography>
      </Button>
      {/* )} */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{
          top: '10px',
          left: '-40px',
        }}
      >
        <Box
          sx={{
            margin: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <TextField
            id="outlined-basic"
            label="New List Name"
            variant="standard"
            value={newListName}
            onChange={(e) => setNewListName(e.target.value)}
          />
          <Button
            aria-label="save list"
            // primary btn
            variant="contained"
            onClick={() => {
              saveList()
              setNewListName('')
            }}
            endIcon={<FileIcon />}
            disabled={!newListName}
          >
            Save
          </Button>
        </Box>
        <List
          sx={{
            width: '300px',
          }}
        >
          {lists.map((x, i) => (
            <ListItem
              key={x.timestamp}
              secondaryAction={
                x.timestamp > 5 && (
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      deleteList(x.name)
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )
              }
              disablePadding
            >
              <ListItemButton
                selected={activeList === x.name}
                role={undefined}
                onClick={() => {
                  setVesselIdsToExport([])
                  setSearchParams(x.filter)
                  setActiveList(x.name)
                  // handleClose()
                }}
                dense
              >
                <ListItemText primary={x.name} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box
          sx={{
            marginTop: '4px',
            padding: '0 12px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Button
            sx={{ marginBottom: '12px' }}
            key="update-list-btn"
            variant="outlined"
            onClick={updateActiveList}
            endIcon={<FileCopyIcon />}
            disabled={!activeListUpdateable()}
          >
            Update Current List
          </Button>
          <Button
            sx={{ marginBottom: '12px' }}
            key="share-list-btn"
            variant="outlined"
            onClick={copyLink}
            endIcon={<FileCopyIcon />}
          >
            {shareText}
          </Button>
        </Box>
      </Popover>
    </>
  )
}

export default SaveLists
