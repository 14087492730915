import { Checkbox, FormControlLabel } from '@mui/material'
import { useState } from 'react'
import { useGetCharterer } from '~hooks/queries/cargo/use-get-charterer'
import { minCharsForAutocomplete, toBeNamedText } from '../../../constants'
import { FieldsGroupProps } from '../../shared-fields'
import { SingleItemAutocomplete } from './base-components/single-item-autocomplete'

export function ChartererAutocomplete({
  formInput,
  updateFormInput,
  missingFields,
  disabledFields,
}: FieldsGroupProps) {
  const [inputValue, setInputValue] = useState('')

  const toBeNamedChecked = formInput.charterer === toBeNamedText

  const { data: charterers } = useGetCharterer({
    chartererName: inputValue,
    disabled:
      toBeNamedChecked ||
      inputValue === toBeNamedText ||
      inputValue.length < minCharsForAutocomplete,
  })

  const options = toBeNamedChecked ? [] : charterers ? [...charterers] : []

  return (
    <>
      <SingleItemAutocomplete
        label="Charterer *"
        input={formInput.charterer}
        setInputValue={setInputValue}
        onChangeHandler={(e, value) =>
          updateFormInput({ charterer: value || undefined })
        }
        options={options}
        hasError={missingFields?.includes('charterer')}
        disabled={
          formInput.charterer === toBeNamedText ||
          disabledFields?.includes('charterer')
        }
      />
      <FormControlLabel
        label="To be named (TBN)"
        control={
          <Checkbox
            disabled={disabledFields?.includes('charterer')}
            checked={formInput.charterer === toBeNamedText}
            onChange={(e, isChecked) =>
              updateFormInput({
                charterer: isChecked ? toBeNamedText : undefined,
              })
            }
          />
        }
      />
    </>
  )
}
