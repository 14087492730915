import { Select } from '@maersktankersdigital/web-components'
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useMemo } from 'react'
import { COLORS } from '~theme/colors'
import {
  cargoStatusesWithHiddenFormFields,
  tradingWorldwide,
} from '../constants'
import { periodOptions } from '../content/select-n-autocomplete-options'
import {
  Area,
  Broker,
  ChartererAutocomplete,
  Comment,
  CommercialOperator,
  CpDate,
  DateInputField,
  Port,
  TimeInputField,
  VesselAutocomplete,
} from './fields'
import { FieldsGroupProps } from './shared-fields'

export function TcFields(props: FieldsGroupProps) {
  const { updateFormInput, formInput, missingFields, disabledFields } = props
  const showExtraFields = useMemo(
    () =>
      !formInput.status ||
      !cargoStatusesWithHiddenFormFields.includes(formInput.status),
    [formInput.status],
  )

  return (
    <>
      <Grid size={{ xs: 12, md: 6, xxxl: 2 }}>
        <Broker {...props} />
      </Grid>

      <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
        <ChartererAutocomplete {...props} />
      </Grid>

      <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
        <Area
          {...props}
          label="trading  area(s)"
          variant="tradingWorldArea"
          disabledFields={[
            ...(disabledFields || []),
            ...(formInput.tradingWorldArea?.includes(tradingWorldwide)
              ? ['tradingWorldArea']
              : []),
          ]}
        />
        <FormControlLabel
          disabled={disabledFields?.includes('tradingWorldArea')}
          label="Trading worldwide"
          control={
            <Checkbox
              checked={formInput.tradingWorldArea?.[0] === tradingWorldwide}
              onChange={(e, isChecked) =>
                updateFormInput({
                  tradingWorldArea: isChecked ? [tradingWorldwide] : undefined,
                })
              }
            />
          }
        />
      </Grid>

      <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
        <Port
          {...props}
          label="Delivery"
          variant="loadPort"
          dependentInput="loadWorldArea"
        />
      </Grid>

      <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
        <Area {...props} label="Delivery world area" variant="loadWorldArea" />
      </Grid>

      <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
        <Port
          {...props}
          label="Redelivery"
          variant="dischargePort"
          dependentInput="dischargeWorldArea"
        />
      </Grid>

      <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
        <Area
          {...props}
          label="Redelivery world area"
          variant="dischargeWorldArea"
        />
      </Grid>
      <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'end',
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Typography
              variant="label"
              color={
                disabledFields?.includes('periodTcOut')
                  ? COLORS.greys.mid
                  : COLORS.secondary.darkBlue
              }
            >
              period
            </Typography>
            <TextField
              sx={{ mt: 2 }}
              value={formInput.periodTcOut}
              type="number"
              onChange={(e) =>
                updateFormInput({ periodTcOut: Number(e.target.value) })
              }
              error={missingFields?.includes('periodTcOut')}
              disabled={disabledFields?.includes('periodTcOut')}
            />
          </Box>
          <Select
            name="periodTcOutType"
            options={periodOptions.map((name) => ({
              label: name,
              value: name,
            }))}
            slotProps={{ popper: { style: { zIndex: 9999 } } }}
            value={formInput.periodTcOutType}
            onChange={(e, value) => updateFormInput({ periodTcOutType: value })}
            // @ts-ignore
            error={missingFields?.includes('periodTcOutType')}
            disabled={disabledFields?.includes('periodTcOutType')}
          />
        </Box>
      </Grid>

      <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            variant="label"
            color={
              disabledFields?.includes('periodDetails')
                ? COLORS.greys.mid
                : COLORS.secondary.darkBlue
            }
          >
            period details
          </Typography>
          <TextField
            sx={{ mt: 2 }}
            value={formInput.periodDetails}
            onChange={(e) => updateFormInput({ periodDetails: e.target.value })}
            error={missingFields?.includes('periodDetails')}
            disabled={disabledFields?.includes('periodDetails')}
          />
        </Box>
      </Grid>

      {showExtraFields && (
        <>
          <Divider sx={{ width: '100%' }} />

          <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
            <VesselAutocomplete {...props} />
          </Grid>

          <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
            <CommercialOperator {...props} />
          </Grid>

          <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
              <Typography
                variant="label"
                color={
                  disabledFields?.includes('subsDueTime')
                    ? COLORS.greys.mid
                    : COLORS.secondary.darkBlue
                }
              >
                Subs due time
              </Typography>
              <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                <DateInputField
                  sx={{ flexGrow: 1 }}
                  onChangeHandler={(value) =>
                    updateFormInput({ subsDueTime: value })
                  }
                  dateTime={formInput.subsDueTime}
                  hasError={missingFields?.includes('subsDueTime')}
                  disabled={disabledFields?.includes('subsDueTime')}
                />
                <TimeInputField
                  onChangeHandler={(value) =>
                    updateFormInput({ subsDueTime: value })
                  }
                  dateTime={formInput.subsDueTime}
                  hasError={missingFields?.includes('subsDueTime')}
                  disabled={disabledFields?.includes('subsDueTime')}
                />
              </Box>
            </Box>
          </Grid>

          <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
            <CpDate {...props} />
          </Grid>

          <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                alignItems: 'end',
                flexWrap: 'wrap',
              }}
            >
              <Box
                sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}
              >
                <Typography
                  variant="label"
                  color={
                    disabledFields?.includes('rateHire')
                      ? COLORS.greys.mid
                      : COLORS.secondary.darkBlue
                  }
                >
                  hire
                </Typography>
                <TextField
                  sx={{ mt: 2 }}
                  value={formInput.rateHire}
                  onChange={(e) =>
                    updateFormInput({ rateHire: e.target.value })
                  }
                  error={missingFields?.includes('rateHire')}
                  disabled={disabledFields?.includes('rateHire')}
                />
              </Box>
              <Typography
                variant="caption"
                color={
                  disabledFields?.includes('rateHire')
                    ? COLORS.greys.mid
                    : COLORS.secondary.darkBlue
                }
              >
                USD/day
              </Typography>
            </Box>
          </Grid>

          <Grid size={{ xs: 12, lg: 6, xxxl: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="label"
                color={
                  disabledFields?.includes('hireDetails')
                    ? COLORS.greys.mid
                    : COLORS.secondary.darkBlue
                }
              >
                hire details
              </Typography>
              <TextField
                sx={{ mt: 2 }}
                value={formInput.hireDetails}
                onChange={(e) =>
                  updateFormInput({ hireDetails: e.target.value })
                }
                error={missingFields?.includes('hireDetails')}
                disabled={disabledFields?.includes('hireDetails')}
              />
            </Box>
          </Grid>

          <Divider sx={{ width: '100%' }} />
        </>
      )}

      <Grid size={12}>
        <Comment {...props} />
      </Grid>
    </>
  )
}
