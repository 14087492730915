export enum CargoStatus {
  Open = 'Open',
  Poss = 'Poss',
  OnSubs = 'On Subs',
  Fixed = 'Fixed',
  Withdrawn = 'Withdrawn',
  Failed = 'Failed',
}

export const quickEditStatusOptions = [
  CargoStatus.OnSubs,
  CargoStatus.Fixed,
  CargoStatus.Failed,
  CargoStatus.Withdrawn,
]

export const statusOptions = Object.values(CargoStatus)

export const quantityUnitOptions = ['MT', 'CBM']
export const rateOptions = ['WS', 'LS', 'USD/MT']
export const periodOptions = ['Months', 'Days']

export enum cargoGradeEnum {
  Chemicals = 'Chemicals',
  CPP = 'CPP',
  DPP = 'DPP',
  IMO = 'IMO',
}
export const cargoGradeOptions = Object.values(cargoGradeEnum)

export const productCodesWithCargoGrades = [
  `Acetone - ${cargoGradeEnum.Chemicals}`,
  `Crude - ${cargoGradeEnum.DPP}`,
  `Alkanes - ${cargoGradeEnum.CPP}`,
  `Alkylate - ${cargoGradeEnum.CPP}`,
  `Ammonia - ${cargoGradeEnum.Chemicals}`,
  `Ammonium Thiosulphate - ${cargoGradeEnum.Chemicals}`,
  `Paraffin - ${cargoGradeEnum.CPP}`,
  `Atm Residue - ${cargoGradeEnum.DPP}`,
  `GO - ${cargoGradeEnum.CPP}`,
  `Avgas - ${cargoGradeEnum.CPP}`,
  `Kerosene - ${cargoGradeEnum.CPP}`,
  `Baseoil - ${cargoGradeEnum.Chemicals}`,
  `Baseoil - ${cargoGradeEnum.CPP}`,
  `Benzene - ${cargoGradeEnum.Chemicals}`,
  `Bio DO - ${cargoGradeEnum.CPP}`,
  `Gasoil - ${cargoGradeEnum.CPP}`,
  `CaCl - ${cargoGradeEnum.Chemicals}`,
  `RSO - ${cargoGradeEnum.Chemicals}`,
  `CBFS - ${cargoGradeEnum.DPP}`,
  `CCS - ${cargoGradeEnum.CPP}`,
  `Nap - ${cargoGradeEnum.CPP}`,
  `CSS - ${cargoGradeEnum.Chemicals}`,
  `Chemicals - ${cargoGradeEnum.Chemicals}`,
  `Clarified Oil - ${cargoGradeEnum.DPP}`,
  `CPP - ${cargoGradeEnum.CPP}`,
  `CCO - ${cargoGradeEnum.IMO}`,
  `Coker Feed - ${cargoGradeEnum.Chemicals}`,
  `Cond - ${cargoGradeEnum.CPP}`,
  `Corn Oil - ${cargoGradeEnum.IMO}`,
  `FO - ${cargoGradeEnum.DPP}`,
  `SBO - ${cargoGradeEnum.IMO}`,
  `Glycerine - ${cargoGradeEnum.IMO}`,
  `SFSO - ${cargoGradeEnum.IMO}`,
  `Crude tall oil - ${cargoGradeEnum.Chemicals}`,
  `DPK - ${cargoGradeEnum.CPP}`,
  `Decant Oil - ${cargoGradeEnum.DPP}`,
  `Dty Cond - ${cargoGradeEnum.DPP}`,
  `DPP - ${cargoGradeEnum.DPP}`,
  `DFA - ${cargoGradeEnum.IMO}`,
  `PFAD - ${cargoGradeEnum.IMO}`,
  `ETBE - ${cargoGradeEnum.Chemicals}`,
  `Ethanol - ${cargoGradeEnum.CPP}`,
  `Ethanol - ${cargoGradeEnum.Chemicals}`,
  `Ethylene Dichloride - ${cargoGradeEnum.Chemicals}`,
  `FAME - ${cargoGradeEnum.Chemicals}`,
  `Fish Oil - ${cargoGradeEnum.IMO}`,
  `FAH - ${cargoGradeEnum.CPP}`,
  `Furnace Oil - ${cargoGradeEnum.DPP}`,
  `Dty GO - ${cargoGradeEnum.DPP}`,
  `GTL - ${cargoGradeEnum.CPP}`,
  `HCO - ${cargoGradeEnum.CPP}`,
  `VGO - ${cargoGradeEnum.CPP}`,
  `LCO - ${cargoGradeEnum.CPP}`,
  `Isomerate - ${cargoGradeEnum.CPP}`,
  `Jet - ${cargoGradeEnum.CPP}`,
  `LSSR FO - ${cargoGradeEnum.DPP}`,
  `Hydrocrackate - ${cargoGradeEnum.CPP}`,
  `Ref - ${cargoGradeEnum.CPP}`,
  `Long Residue - ${cargoGradeEnum.DPP}`,
  `LSWR - ${cargoGradeEnum.DPP}`,
  `Lysine - ${cargoGradeEnum.IMO}`,
  `MgCl - ${cargoGradeEnum.Chemicals}`,
  `Masirah Crude oil - ${cargoGradeEnum.DPP}`,
  `MCCS - ${cargoGradeEnum.CPP}`,
  `Methanol - ${cargoGradeEnum.Chemicals}`,
  `Mixed Aro - ${cargoGradeEnum.CPP}`,
  `Mol - ${cargoGradeEnum.IMO}`,
  `MSC - ${cargoGradeEnum.DPP}`,
  `MTBE - ${cargoGradeEnum.Chemicals}`,
  `NRD - ${cargoGradeEnum.CPP}`,
  `PME - ${cargoGradeEnum.IMO}`,
  `PX - ${cargoGradeEnum.Chemicals}`,
  `Paraffin Wax - ${cargoGradeEnum.Chemicals}`,
  `Ph Acid - ${cargoGradeEnum.Chemicals}`,
  `POME - ${cargoGradeEnum.IMO}`,
  `Process Water - ${cargoGradeEnum.DPP}`,
  `Pygas - ${cargoGradeEnum.CPP}`,
  `Raf - ${cargoGradeEnum.CPP}`,
  `Synthetic aviation fuel - ${cargoGradeEnum.CPP}`,
  `SBEO - ${cargoGradeEnum.IMO}`,
  `Shale Oil Blend - ${cargoGradeEnum.DPP}`,
  `BO - ${cargoGradeEnum.IMO}`,
  `Slop - ${cargoGradeEnum.DPP}`,
  `Slurry - ${cargoGradeEnum.DPP}`,
  `Sodium Hydrosulphide - ${cargoGradeEnum.Chemicals}`,
  `SME - ${cargoGradeEnum.Chemicals}`,
  `SM - ${cargoGradeEnum.Chemicals}`,
  `S.Acid - ${cargoGradeEnum.Chemicals}`,
  `Tallow - ${cargoGradeEnum.IMO}`,
  `TAME - ${cargoGradeEnum.CPP}`,
  `Tert Butyl Alcohol - ${cargoGradeEnum.Chemicals}`,
  `Tetramer - ${cargoGradeEnum.Chemicals}`,
  `Toluene - ${cargoGradeEnum.Chemicals}`,
  `TX-Cut - ${cargoGradeEnum.CPP}`,
  `ULSD - ${cargoGradeEnum.CPP}`,
  `Heating Oil - ${cargoGradeEnum.CPP}`,
  `ULSP - ${cargoGradeEnum.CPP}`,
  `Dirty Condensate - ${cargoGradeEnum.DPP}`,
  `UAN - ${cargoGradeEnum.Chemicals}`,
  `UMS - ${cargoGradeEnum.CPP}`,
  `UCO - ${cargoGradeEnum.IMO}`,
  `Veg - ${cargoGradeEnum.IMO}`,
  `VGO Hydrocrackate - ${cargoGradeEnum.DPP}`,
  `Waste oil - ${cargoGradeEnum.DPP}`,
  `Xylene - ${cargoGradeEnum.Chemicals}`,
]
