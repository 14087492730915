import { useQuery } from '@tanstack/react-query'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'

export interface GetPortsResponse {
  Country: string
  Name: string
  Area: string
}

export function useGetPorts({ portName }: { portName?: string }) {
  const fetch = useFetch<GetPortsResponse[]>()
  const url = `/cargo/ports?portName=${portName}`

  return useQuery({
    queryKey: [url],
    queryFn: () => fetch(`${apiBase()}${url}`),
    enabled: !!portName,
  })
}
