import { Autocomplete, Chip, TextField, Typography } from '@mui/material'
import { COLORS } from '~theme/colors'

export function MultiItemAutocomplete({
  input,
  setInputValue,
  label,
  onChangeHandler,
  options,
  hasError,
  disabled,
  ...rest
}: {
  input: string[]
  setInputValue?: (value: string) => void
  label: string
  onChangeHandler: (e: any, newValues: string[]) => void
  options: any[]
  hasError?: boolean
  disabled?: boolean
  [key: string]: any
}) {
  const optionsWithoutInput = options.filter(
    (option) => !input.includes(option),
  )

  return (
    <Autocomplete
      value={input}
      sx={{
        '.MuiFormControl-root': { mt: '26px' },
        '.MuiOutlinedInput-root': { p: 0 },
        '.MuiInputLabel-formControl': { mt: '5px' },
      }}
      slotProps={{
        listbox: {
          role: 'list-box',
        },
      }}
      freeSolo
      multiple
      disablePortal
      disableClearable
      forcePopupIcon={false}
      options={optionsWithoutInput}
      renderTags={(value: readonly string[], getTagProps) => {
        return value.map((option: string, index: number) => {
          const { key, ...tagProps } = getTagProps({ index })
          return <Chip key={key} label={option} {...tagProps} />
        })
      }}
      onChange={onChangeHandler}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ p: 0 }}
          label={
            <Typography
              variant="label"
              sx={{ color: disabled ? COLORS.greys.mid : '' }}
            >
              {label}
            </Typography>
          }
          placeholder={disabled ? '' : 'Search...'}
          error={hasError}
        />
      )}
      onInputChange={(event, newInputValue) => {
        setInputValue?.(newInputValue)
      }}
      disabled={disabled}
      disableCloseOnSelect
      limitTags={1}
      {...rest}
    />
  )
}
