import { Box, TextField, Typography } from '@mui/material'
import { COLORS } from '~theme/colors'
import { FieldsGroupProps } from '../shared-fields'

export function Comment({
  formInput,
  updateFormInput,
  missingFields,
  disabledFields,
}: FieldsGroupProps) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Typography
        variant="label"
        color={
          disabledFields?.includes('comments')
            ? COLORS.greys.mid
            : COLORS.secondary.darkBlue
        }
      >
        comment
      </Typography>
      <TextField
        placeholder="..."
        sx={{ mt: 2 }}
        value={formInput.comments}
        onChange={(e) => updateFormInput({ comments: e.target.value })}
        error={missingFields?.includes('comments')}
        multiline
        minRows={3}
        disabled={disabledFields?.includes('comments')}
      />
    </Box>
  )
}
