import { useQuery } from '@tanstack/react-query'
import { tenMinutes } from '~constants/constants'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'

export interface GetUsersVtResItem {
  sub: string
  email: string
  name: string
  poolpartners: any[]
  roles: any[]
}

export function useGetUsersVt({
  onlyInternal,
  name,
  disabled,
}: { onlyInternal?: boolean; name?: string; disabled?: boolean } = {}) {
  const fetchRequest = useFetch<GetUsersVtResItem[]>()
  const params = new URLSearchParams()

  if (onlyInternal) {
    params.append('mt', 'true')
  }
  if (name) {
    params.append('name', name)
  }

  const url = `/users${params.toString() ? `?${params.toString()}` : ''}`

  return useQuery({
    queryKey: [url],
    queryFn: () => fetchRequest(`${apiBase()}${url}`),
    staleTime: tenMinutes,
    enabled: !disabled,
  })
}
