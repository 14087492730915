import { Typography } from '@maersktankersdigital/web-components'
import { Popup } from '@mui/base/Unstable_Popup/Popup'
import * as React from 'react'
import { useState } from 'react'

import { LastSirePopupContent } from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/last-sire-content/last-sire-content.styles'
import { formatDate } from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/position-list-table-content/position-list-table-content'
import { LastSire } from '~pages/pages-behind-login/chartering/types/position-list-types'

interface Props {
  lastSire: LastSire
}

function LastSireContent({ lastSire }: Props) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const open = Boolean(anchor)

  const handleMouseOver = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget)
  }

  const handleMouseLeave = () => {
    setAnchor(null)
  }

  return (
    <div onMouseLeave={handleMouseLeave}>
      <div onMouseOver={handleMouseOver}>
        {formatDate(lastSire?.inspectionDate)}
      </div>
      <Popup open={open} anchor={anchor}>
        <LastSirePopupContent>
          <Typography variant="caption" as="div">
            {lastSire?.approval}
          </Typography>
          <Typography variant="caption" as="div">
            {lastSire?.port}
          </Typography>
        </LastSirePopupContent>
      </Popup>
    </div>
  )
}

export default LastSireContent
