import { useMutation } from '@tanstack/react-query'
import { FormInput } from '~pages/pages-behind-login/chartering/cargo-list-page/componenets/cargo-modal/add-cargo-modal'
import { GetCargoItem } from '~pages/pages-behind-login/chartering/cargo-list-page/hooks/use-get-cargo'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'

export interface PostCargoPayload {
  cargoGrade: string | string[]
  charterer: string
  dateQuoted: Date
  laycanFrom: Date
  laycanTo: Date
  pic: string
  pool: string | string[]
  privateAndConfidential: boolean
  quantity: number
  quantityType: string
  status: string
  tradingType: string
  broker?: string
  cpDate?: Date
  comments?: string
  commercialOperator?: string
  dischargePort?: string | string[]
  dischargeWorldArea?: string | string[]
  hireDetails?: string
  loadPort?: string | string[]
  loadWorldArea?: string | string[]
  periodDetails?: string
  periodTcOut?: number
  periodTcOutType?: string
  rateHire?: string
  rateHireType?: string
  reminderDate?: Date
  subsDueTime?: Date
  tradingWorldArea?: string
  vessel?: string
}

export interface PatchCargoPayload extends FormInput {
  _id: string
}

export function usePostCargo() {
  const fetchRequest = useFetch()
  return useMutation({
    mutationFn: (payload: PostCargoPayload) =>
      fetchRequest(`${apiBase()}/cargo`, undefined, payload, 'POST'),
  })
}

export function useDeleteCargo() {
  const fetchRequest = useFetch()
  return useMutation({
    mutationFn: (id: string) =>
      fetchRequest(`${apiBase()}/cargo/${id}`, undefined, undefined, 'DELETE'),
  })
}

export function usePatchCargo() {
  const fetchRequest = useFetch<GetCargoItem>()
  return useMutation({
    mutationFn: (payload: PatchCargoPayload) =>
      fetchRequest(
        `${apiBase()}/cargo/${payload._id}`,
        undefined,
        payload,
        'PATCH',
      ),
  })
}
