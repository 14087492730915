import { useEventListener } from '@maersktankersdigital/web-components'
import { Popup } from '@mui/base/Unstable_Popup/Popup'
import * as React from 'react'
import { useState } from 'react'

import EditCargoesModal from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/l3ccontent/edit-cargoes-modal'
import InternalCargoesHoverContent from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/l3ccontent/internal-cargoes-hover-content'
import {
  BottomBorder,
  InternalCargoesWrapper,
  InternalL3CWrapper,
  L3C,
  L3CWrapper,
  VoyagesWrapper,
  Wrapper,
} from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/l3ccontent/l3ccontent.styles'
import VoyagesPopupContent from '~pages/pages-behind-login/chartering/position-list-page/position-list-table/l3ccontent/voyages-popup-content'
import { LastThreeCargoes } from '~pages/pages-behind-login/chartering/types/position-list-types'
import { Voyage } from '~types/itinerary.types'

interface Props {
  currentAnchor: HTMLElement | null
  internalLastThreeCargoes?: LastThreeCargoes
  isOpen: boolean
  setAnchor: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  vesselId: string
  vesselName: string
  voyages: Voyage[]
}

export function getLastThreeVoyages(voyages: Voyage[] | undefined) {
  if (!voyages || voyages.length === 0) return

  return voyages
    .filter(
      ({ productCodes, cargoGrades }) =>
        productCodes.length > 0 || cargoGrades.length > 0,
    )
    .slice(0, 3)
}

function L3CContent({
  voyages,
  vesselName,
  vesselId,
  internalLastThreeCargoes,
  currentAnchor,
  setAnchor,
  isOpen,
}: Props) {
  const [internalAnchor, setInternalAnchor] = useState<HTMLElement | null>(null)
  const [editModalOpen, setEditModalOpen] = useState(false)

  useEventListener('keydown', (e) => {
    if (e.key === 'Escape' || e.key === 'Esc') {
      setAnchor(null)
    }
  })

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    if (currentAnchor === event.currentTarget) {
      setAnchor(null)
    }
  }

  const handleMouseOver = (event: React.MouseEvent<HTMLElement>) => {
    if (event.currentTarget.id === 'voyages') {
      setInternalAnchor(null)
      setAnchor(event.currentTarget)
    } else if (event.currentTarget.id === 'internal') {
      setAnchor(null)
      setInternalAnchor(event.currentTarget)
    }
  }

  const handleMouseLeave = () => {
    setAnchor(null)
    setInternalAnchor(null)
  }

  const open = Boolean(currentAnchor) && isOpen
  const internalOpen = Boolean(internalAnchor) && isOpen

  const lastThreeVoyages = getLastThreeVoyages(voyages)

  return (
    <Wrapper onMouseLeave={handleMouseLeave}>
      <VoyagesWrapper
        id="voyages"
        onClick={handleClick}
        onMouseOver={handleMouseOver}
      >
        <L3CWrapper>
          {lastThreeVoyages?.map((voyage: Voyage, i: number) => {
            return (
              voyage.productCodes && <L3C key={i}>{voyage.productCodes}</L3C>
            )
          })}
          <BottomBorder $isOpen={open} />
        </L3CWrapper>
      </VoyagesWrapper>
      {internalLastThreeCargoes?.lastThreeCargoes && (
        <InternalCargoesWrapper id="internal" onMouseOver={handleMouseOver}>
          <InternalL3CWrapper>
            {internalLastThreeCargoes.lastThreeCargoes}
            <BottomBorder $isOpen={internalOpen} />
          </InternalL3CWrapper>
        </InternalCargoesWrapper>
      )}
      <Popup
        open={open}
        anchor={currentAnchor}
        placement="top"
        style={{ zIndex: 5 }}
      >
        <VoyagesPopupContent
          vesselName={vesselName}
          voyages={lastThreeVoyages}
          internalLastThreeCargoes={internalLastThreeCargoes}
          setEditModalOpen={setEditModalOpen}
        />
      </Popup>
      <Popup
        open={internalOpen}
        anchor={internalAnchor}
        placement="bottom-start"
        style={{ zIndex: 5 }}
      >
        <InternalCargoesHoverContent
          internalLastThreeCargoes={internalLastThreeCargoes}
          editModalOpen={editModalOpen}
          setEditModalOpen={setEditModalOpen}
        />
      </Popup>
      <EditCargoesModal
        editModalOpen={editModalOpen}
        setEditModalOpen={setEditModalOpen}
        internalLastThreeCargoes={internalLastThreeCargoes}
        vesselId={vesselId}
        voyages={voyages}
      />
    </Wrapper>
  )
}

export default L3CContent
