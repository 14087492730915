import { Typography } from '@mui/material'
import { useMemo, useState } from 'react'
import {
  GetPortsResponse,
  useGetPorts,
} from '~hooks/queries/cargo/use-get-ports'
import { MultiItemAutocomplete } from '.'
import { minCharsForAutocomplete } from '../../constants'
import { FormInput } from '../cargo-modal/add-cargo-modal'
import { LocationProps } from './area'

export function Port({
  updateFormInput,
  formInput,
  variant,
  dependentInput,
  label,
  missingFields,
  disabledFields,
}: LocationProps) {
  const [inputValue, setInputValue] = useState('')
  const [invalidInput, setInvalidInput] = useState(false)
  const { data } = useGetPorts({
    portName:
      inputValue.length >= minCharsForAutocomplete ? inputValue : undefined,
  })

  const portNames = useMemo(
    () => data?.map((port: { Name: string }) => port.Name) || [],
    [data],
  )

  const input =
    Array.isArray(formInput[variant]) &&
    formInput[variant].every((item) => typeof item === 'string')
      ? [...formInput[variant]]
      : []

  const onChangeHandler = (e: any, newValues: string[]) => {
    const newlyAddedPortName = newValues.find((value) => !input.includes(value))
    if (
      newlyAddedPortName &&
      portNames.every(
        (port) => port.toLowerCase() !== newlyAddedPortName?.toLowerCase(),
      )
    ) {
      setInvalidInput(true)
      return
    }

    setInvalidInput(false)

    if (!newlyAddedPortName) {
      updateFormInput({ [variant]: newValues })
      return
    }

    updateFormInput({
      [variant]: newValues,
      ...(!dependentInput
        ? {}
        : {
            [dependentInput]: constructSelectedWorldAreas({
              data,
              portName: newlyAddedPortName,
              formInput,
              dependentInput,
            }),
          }),
    })
  }

  return (
    <>
      <MultiItemAutocomplete
        input={input}
        setInputValue={setInputValue}
        label={label}
        onChangeHandler={onChangeHandler}
        options={portNames}
        hasError={missingFields?.includes(variant)}
        disabled={disabledFields?.includes(variant)}
      />
      {invalidInput && (
        <Typography variant="caption">
          To input a port, type {minCharsForAutocomplete} characters and select
          one of the options
        </Typography>
      )}
    </>
  )
}

function constructSelectedWorldAreas({
  data,
  portName,
  formInput,
  dependentInput,
}: {
  data: GetPortsResponse[] | undefined
  portName: string
  formInput: FormInput
  dependentInput: keyof FormInput
}) {
  const area = data?.find((port) => port.Name === portName)?.Area

  const loadWorldArea =
    Array.isArray(formInput[dependentInput]) &&
    formInput[dependentInput].every((item) => typeof item === 'string')
      ? [...formInput[dependentInput]]
      : []

  const selectedWorldAreas =
    loadWorldArea.length === 0
      ? area
        ? [area]
        : []
      : Array.from(new Set(loadWorldArea).add(area))

  return selectedWorldAreas
}
