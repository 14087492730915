import {
  Header,
  TopBar,
  useBreakpoint,
} from '@maersktankersdigital/web-components'
import { AppBar } from '@mui/material'
import { useMatches } from 'react-router-dom'
import { ExactScopeName, useHasScope } from '~hooks/permissions/use-has-scope'
import { useConstructLinkWithPool } from '~hooks/use-construct-link-with-pool'
import { PageRoute } from '~routing/constants/page-route'
import { useSubheaderLinks } from './hooks/use-subheader-links'

export function TopNavigation() {
  const { isMobileSize } = useBreakpoint()
  const matches = useMatches()
  const subheaderLinks = useSubheaderLinks()
  const routeWithLeftNavigation = matches.some(
    // @ts-ignore
    (x) => x.handle && !x.handle.disableLeftNavigation,
  )

  const isTechnicalManager = useHasScope(ExactScopeName.technicalManager)
  const isMtUser = useHasScope(ExactScopeName.maerskTankersUser)
  const isAccountManager = useHasScope(ExactScopeName.accountManager)
  const isSuperUser = useHasScope(ExactScopeName.superUser)

  const dashboardLink = useConstructLinkWithPool('/panel/dashboard/:pool')
  const emissionsLink = useConstructLinkWithPool(PageRoute.EMISSIONS)

  const topBarHeaders: Header[] = [
    ...(isTechnicalManager
      ? []
      : [
          {
            title: 'dashboard',
            link: dashboardLink,
            isExternal: false,
          },
        ]),
    {
      title: 'financials',
      link: 'panel/financials',
    },
    ...(isTechnicalManager
      ? []
      : [
          {
            title: 'emissions',
            link: emissionsLink,
          },
        ]),
    { title: "my fleet", link: '/vessels' },
    ...(!isMtUser ? [] : [{ title: 'chartering', link: PageRoute.CHARTERING }]),
    ...(!(isAccountManager || isSuperUser)
      ? []
      : [
          {
            title: 'users',
            link: PageRoute.USERS,
          },
          {
            title: 'pool partners',
            link: PageRoute.POOL_PARTNERS,
          },
        ]),
  ]

  return (
    <>
      <AppBar
        className="tour-step"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: 'sticky',
          '& a': {
            textDecoration: 'none',
          },
        }}
      >
        <TopBar
          headers={topBarHeaders}
          subHeaders={subheaderLinks}
          isHamburgerMenu={isMobileSize && routeWithLeftNavigation}
        />
      </AppBar>
    </>
  )
}
