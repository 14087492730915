export type Distance = {
  ETA: string
  distance_naut: number
  port_name: string
}

export enum PortFunction {
  COMMENCING = 'Commencing',
  FUELING = 'Fueling',
  LOADING = 'Loading',
  WAITING = 'Waiting',
  DISCHARGING = 'Discharging',
  OTHER = 'Other',
  CANAL_TRANSIT = 'Canal Transit',
  REPAIR = 'Repair',
  CHARTERERS_ORDERS = "Charterer's Orders",
  TERMINATING = 'Terminating',
  DELIVERY = 'Delivery',
  FOR_ORDERS = 'For Orders',
  MULTI_PURPOSE = 'Multi-purpose',
  REDELIVERY = 'Redelivery',
  PASSING = 'Passing',
}

export interface User {
  email: string
  name: string
}

export type VoyageItinerary = {
  arrDepStatus: 'AR' | 'SA' | 'TE'
  arrivalGmt?: string
  arrivalLocal?: string
  blCodes?: string[]
  cargoGrades?: string[]
  departureGmt?: string
  departureLocal?: string
  portFunction: PortFunction
  portName: string
  seq: number
  updatedAt?: string
}

export enum ActualImpairedEarnings {
  IMPARED = 'IMPARED',
  ACTUAL = 'ACTUAL',
}

export enum StatusValues {
  OPEN = 'open',
  FOR_ORDERS = 'forOrders',
  IN_BALLAST = 'inBallast',
  CLEANING = 'cleaning',
  OFFERED = 'offered',
  ON_SUBS = 'onSubs',
  UNCERTAIN = 'uncertain',
  ON_HOLD = 'onHold',
  DRY_DOCK = 'dryDock',
  TC_OUT = 'tcOut',
  REDELIVERY = 'redelivery',
  FIXED = 'fixed',
}

export enum VoyageStatus {
  SCHEDULED = 'Scheduled',
  CLOSED = 'Closed',
  COMPLETED = 'Completed',
  COMMENCED = 'Commenced',
}

export type Voyage = {
  _id?: string
  worldArea: { level1: string; level2: string; level3: string }
  openPort: string
  openDate: string
  oprType: 'TCOV' | 'TCTO'
  ballastPort: string
  blCodes: string
  cargoGrades: string
  cargoGroup: string
  chartererShortName: string
  commenceDate: string
  completeDate?: string
  cpDate: string
  dischargePort: string
  distances?: Distance[]
  fixtureDate: string | null
  itineraries: VoyageItinerary[]
  lastUpdateGmt?: string
  loadPort: string
  operator?: string
  productCodes: string
  status: VoyageStatus
  vesselCode: string
  voyageNumber: string
  actualEstimatedDaysDelta: number
  actualImpairedEarnings: ActualImpairedEarnings
  actualImpairedEarningsComment: string
  actualTce: number
  actualTceDelta: number
  estimatedTce: number
}

export type IComment = {
  comment?: string
  createdAt?: string
  field?: string
  hash?: string
  responsibleUser?: User
  tab?: string
  updatedAt?: string
  updatedBy?: string
  updatedResponsibleUser?: User
}

export enum ConsumptionLevel {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export enum CII {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
}

export interface Consumption {
  ladenTonsPerDay: number
  ladenTonsPerDayPoolAvg: number
  ladenTonsPerDayPoolRating: ConsumptionLevel
}

export interface LastSire {
  approval: string
  inspectionDate: string
  port: string
}

export interface LastThreeCargoes {
  comment?: string
  createdAt?: string
  lastThreeCargoes?: string
  responsibleUser?: User
}

export interface ResponsibleUser {
  name: string
  sub: string
}

export interface Rejection {
  canImpairEarnings: boolean
  closedBy: string
  closingDate: string
  hash: string
  isOngoing: boolean
  oilMajor: string
  rejectionCause: string
  rejectionDate: string
  responsibleUser: ResponsibleUser
  updatedAt: string
  updatedBy: string
}

export type PLVessel = {
  dwt: string
  cbm: number | string
  cargo: {
    cargoId: string
  }[]
  beam: string
  bwts: string
  redeliveryDate: string
  consumption: string
  draft: number
  flag: string
  igsFlueGasVsIgg: string
  biofuelCapable: string
  ciiRating: CII
  imoNumber: string
  lastThreeCargoesInternal: LastThreeCargoes
  status: StatusValues
  tradeAreaName: string
  vesselStatus: string
  n2: string
  openInt: string
  rejections: Rejection[]
  imoClass: string
  iceClass: string
  igs: string | { val: string }
  igsSupply: string | { val: string; updatedAt: string }
  lastUpdateGMT: string
  loa: string
  comments: IComment[]
  lastSIRE: LastSire
  mouldedDepth: string
  nextDryDock: string
  operator: string
  pbl: string
  pool: string
  scrubber: string
  sternLine: string
  tankCoating: string
  usCOC: string
  yearBuilt: string
  deliveryDate: string
  vesselId: string
  openPort: string
  openDate: string
  worldArea: {
    level1: string
    level2: string
    level3: string
  }
  isDuplicatedParent?: boolean
  isDuplicated?: boolean
  lastCargoGroup: string
  aisDistances: AISDistances
  vessel: string
  voyages: Voyage[]
  usCoc: {
    issued: Date
    lastAnnual: Date
  }
  lastSire: LastSire
  nextDryDockInternal: {
    nextDryDock: string
    responsibleUser: User
    comment: string
    createdAt: string
  }
  lastUpdateGmt: string
  summerDraft: string
  bunkerConsumption: Consumption
  dpp: string
}

export interface AISDistances {
  aisPositionReceived: string
  distances: Distance[]
  latitude: number
  longtiude: number
}

export const widthMap = {
  Default: '90px',
  Beam: '90px',
  Checkbox: '50px',
  'Vessel Name': '200px',
  TradeSymbolsContent: '120px',
  'Vessel Status': '140px',
  L3C: '217px',
  LOA: '90px',
  'Open Date': '110px',
  'Open Port': '130px',
  Comments: '280px',
  'Level 1': '110px',
  'Level 2': '110px',
  'Level 3': '110px',
  'Last SIRE': '100px',
  'US COC': '110px',
  'Next Dry Dock': '135px',
  'IGS - Flue Gas vs IGG': '165px',
  IMO: '60px',
  BWTS: '80px',
  Scrubber: '110px',
  'ETA Amsterdam': '90px',
  'ETA Fujairah': '90px',
  'ETA Houston': '90px',
  'ETA Malta': '90px',
  'ETA Singapore': '90px',
  'ETA Yosu': '90px',
  'ETA Sikka': '90px',
  'ETA Yanbu': '90px',
  'ETA Gibraltar': '90px',
  Flag: '100px',
  Operator: '140px',
  Consumption: '130px',
  'Last Update GMT': '150px',
  'Moulded Depth': '140px',
  ICE: '105px',
  'Stern Line': '110px',
  'Tank Coating': '135px',
  'Year Built': '110px',
  Status: '140px',
}

export const noLevel = [
  {
    label: 'No World Area',
    value: '1No World Area',
    level: '',
  },
]

export const level1 = [
  {
    label: 'Americas',
    value: '1Americas',
    level: 'Level 1',
  },
  {
    label: 'East of Suez',
    value: '1East of Suez',
    level: 'Level 1',
  },
  {
    label: 'Europe',
    value: '1Europe',
    level: 'Level 1',
  },
]

export const level2EastOfSuez = [
  {
    label: 'AG',
    value: '2AG',
    level: 'Level 2 East of Suez',
  },
  {
    label: 'ASIA',
    value: '2ASIA',
    level: 'Level 2 East of Suez',
  },
]

export const level2Europe = [
  {
    label: 'MED',
    value: '2MED',
    level: 'Level 2 Europe',
  },
  {
    label: 'NWE',
    value: '2NWE',
    level: 'Level 2 Europe',
  },
  {
    label: 'WAF (L2)',
    value: '2WAF',
    level: 'Level 2 Europe',
  },
]

export const level2Americas = [
  {
    label: 'Atlantic Basin',
    value: '2Atlantic Basin',
    level: 'Level 2 Americas',
  },
  {
    label: 'Pacific Basin',
    value: '2Pacific Basin',
    level: 'Level 2 Americas',
  },
]

export const level3AmericasAtlanticBasin = [
  {
    label: 'EC North America',
    value: '3EC North America',
    level: 'Level 3 Americas/Atlantic Basin',
  },
  {
    label: 'EC SAM',
    value: '3EC SAM',
    level: 'Level 3 Americas/Atlantic Basin',
  },
  {
    label: 'USG-Caribs',
    value: '3USG-Caribs',
    level: 'Level 3 Americas/Atlantic Basin',
  },
]

export const level3AmericasPacificBasin = [
  {
    label: 'WC Latin America',
    value: '3WC Latin America',
    level: 'Level 3 Americas/Pacific Basin',
  },
]

export const level3EastOfSuezAG = [
  {
    label: 'WC India',
    value: '3WC India',
    level: 'Level 3 East Of Suez/AG',
  },
  {
    label: 'Red Sea',
    value: '3Red Sea',
    level: 'Level 3 East Of Suez/AG',
  },
  {
    label: 'Middle East',
    value: '3Middle East',
    level: 'Level 3 East Of Suez/AG',
  },
  {
    label: 'East & South Africa',
    value: '3East- & South Africa',
    level: 'Level 3 East Of Suez/AG',
  },
]

export const level3EastOfSuezAsia = [
  {
    label: 'EC Australia',
    value: '3EC Australia',
    level: 'Level 3 East Of Suez/Asia',
  },
  {
    label: 'EC India',
    value: '3EC India',
    level: 'Level 3 East Of Suez/Asia',
  },
  {
    label: 'N Asia',
    value: '3N Asia',
    level: 'Level 3 East Of Suez/Asia',
  },
  {
    label: 'SE Asia',
    value: '3SE Asia',
    level: 'Level 3 East Of Suez/Asia',
  },
  {
    label: 'USWC',
    value: '3USWC',
    level: 'Level 3 East Of Suez/Asia',
  },
  {
    label: 'WC Australia',
    value: '3WC Australia',
    level: 'Level 3 East Of Suez/Asia',
  },
]

export const level3EuropeMED = [
  {
    label: 'Black Sea',
    value: '3Black sea',
    level: 'Level 3 Europe/MED',
  },
  {
    label: 'Center Med',
    value: '3Center Med',
    level: 'Level 3 Europe/MED',
  },
  {
    label: 'East Med',
    value: '3East Med',
    level: 'Level 3 Europe/MED',
  },
  {
    label: 'West Med',
    value: '3West Med',
    level: 'Level 3 Europe/MED',
  },
]

export const level3EuropeNWE = [
  {
    label: 'Atlantic',
    value: '3Atlantic',
    level: 'Level 3 Europe/NWE',
  },
  {
    label: 'Baltic',
    value: '3Baltic',
    level: 'Level 3 Europe/NWE',
  },
  {
    label: 'UKC',
    value: '3UKC',
    level: 'Level 3 Europe/NWE',
  },
]

export const level3EuropeWAF = [
  {
    label: 'WAF (L3)',
    value: '3WAF',
    level: 'Level 3 Europe/WAF',
  },
]

export const allLevel3Filters = [
  ...level3AmericasAtlanticBasin,
  ...level3AmericasPacificBasin,
  ...level3EastOfSuezAG,
  ...level3EastOfSuezAsia,
  ...level3EuropeMED,
  ...level3EuropeNWE,
  ...level3EuropeWAF,
]

export const allAreaLevels = [
  ...level1,
  ...level2EastOfSuez,
  ...level2Europe,
  ...level2Americas,
  ...level3AmericasAtlanticBasin,
  ...level3AmericasPacificBasin,
  ...level3EastOfSuezAG,
  ...level3EastOfSuezAsia,
  ...level3EuropeMED,
  ...level3EuropeNWE,
  ...level3EuropeWAF,
]

export const allLevelFilters = [...noLevel, ...allAreaLevels]

export const L3ToL2Map = {
  'WC Latin America': 'Pacific Basin',
  'USG-Caribs': 'Atlantic Basin',
  'EC SAM': 'Atlantic Basin',
  'EC North America': 'Atlantic Basin',
  'East- & South Africa': 'AG',
  'WC India': 'AG',
  'Red Sea': 'AG',
  'Middle East': 'AG',
  'SE Asia': 'ASIA',
  'N Asia': 'ASIA',
  USWC: 'ASIA',
  'WC Australia': 'ASIA',
  'EC Australia': 'ASIA',
  'EC India': 'ASIA',
  'West Med': 'MED',
  'Center Med': 'MED',
  'East Med': 'MED',
  'Black sea': 'MED',
  UKC: 'NWE',
  Atlantic: 'NWE',
  Baltic: 'NWE',
  WAF: 'WAF',
}

export const L2ToL1Map = {
  'Atlantic Basin': 'Americas',
  'Pacific Basin': 'Americas',
  AG: 'East of Suez',
  ASIA: 'East of Suez',
  MED: 'Europe',
  NWE: 'Europe',
  WAF: 'Europe',
}

export const VesselSpecs = [
  'BWTS',
  'Beam',
  'CBM',
  'Consumption',
  'Draft',
  'DWT',
  'DPP',
  'Flag',
  'IGS - Flue Gas vs IGG',
  'IMO',
  'ICE',
  'IGS',
  'Last Update GMT',
  'LOA',
  'Last SIRE',
  'Moulded Depth',
  'Next Dry Dock',
  'Operator',
  'PBL',
  'Scrubber',
  'Stern Line',
  'Tank Coating',
  'US COC',
  'Year Built',
]

export const ETAFilters = [
  { label: 'Gibraltar', value: 'ETA Gibraltar' },
  { label: 'Houston', value: 'ETA Houston' },
  { label: 'Amsterdam', value: 'ETA Amsterdam' },
  { label: 'Singapore', value: 'ETA Singapore' },
  { label: 'Yanbu', value: 'ETA Yanbu' },
  { label: 'Yosu', value: 'ETA Yosu' },
  { label: 'Fujairah', value: 'ETA Fujairah' },
  { label: 'Sikka', value: 'ETA Sikka' },
  { label: 'Malta', value: 'ETA Malta' },
]

export const IntermediateCityOptions = [
  'Year Built',
  'DWT',
  'CBM',
  'ICE',
  'Open Port',
  'Open Date',
  'Status',
  'Comments',
]
