import { useQuery } from '@tanstack/react-query'
import { apiBase } from '~utils/base-url'
import { useFetch } from '~utils/fetch-request'
import { Type } from '../componenets/cargo-modal/add-cargo-modal'
import { CargoStatus } from '../content/select-n-autocomplete-options'

export function useGetCargo() {
  const fetchFn = useFetch<GetCargoItem[]>()
  const url = '/cargo'

  return useQuery({
    queryKey: [url],
    queryFn: () => fetchFn(`${apiBase()}${url}`),
  })
}

export interface GetCargoItem {
  _id: string
  broker?: string
  cargo?: string
  cargoGrade?: string[]
  charterer: string
  commercialOperator?: string
  comments?: string
  commentsCreatedAt?: string
  commentsCreatedBy?: string
  cpDate?: string
  createdAt: string
  createdBy: string
  dateQuoted?: string
  dischargePort: string[]
  dischargeWorldArea?: string[]
  euEts?: string
  failedInstances?: string[]
  hireDetails?: string
  history?: History[]
  isMtFleet?: boolean
  isCommercialManagement?: boolean
  laycan?: string
  laycanFrom?: string
  laycanTo?: string
  loadPort: string[]
  loadWorldArea?: string[]
  owner?: string
  periodDetails?: string
  periodTcOut?: number
  periodTcOutType?: string
  pic?: string
  pool: string | string[]
  privateAndConfidential?: boolean
  quantity?: number | string
  quantityType?: string
  rate?: string
  rateHire?: string
  rateHireType?: string
  reminderDate?: string
  status: CargoStatus
  subsDueTime?: string
  tradingType?: Type
  tradingWorldArea?: string[]
  updatedAt?: string
  updatedBy?: string
  vessel?: string
  worldArea?: string
}

interface History {
  tradingType?: string
  privateAndConfidential?: boolean
  laycanTo?: Date
  dateQuoted?: Date
  pic?: string
  status: CargoStatus
  subsDueTime?: Date
  laycanFrom?: Date
  cargoGrade?: string
  quantity?: number
  quantityType?: string
  loadPort?: string[] | string
  loadWorldArea?: string[]
  dischargePort?: string[] | string
  dischargeWorldArea?: string[]
  broker?: string
  charterer: string
  vessel?: string
  commercialOperator?: string
  rateHire?: string
  rateHireType?: string
  cpDate?: Date
  comments?: string
  pool?: string
  createdBy?: string
  createdAt?: Date
  tradingWorldArea?: string[]
  hireDetails?: string
  periodTcOut?: number
  periodTcOutType?: string
  periodDetails?: string
  cargo?: string
  updatedBy?: string
  updatedAt?: Date
  laycan?: Date
  worldArea?: string
}
