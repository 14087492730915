import { useGetMe } from '~hooks/queries/me/use-get-me'
import { MultiItemAutocomplete } from '..'
import { FieldsGroupProps } from '../../shared-fields'

export function PoolAutocomplete({
  formInput,
  updateFormInput,
  missingFields,
  disabledFields,
}: FieldsGroupProps) {
  const { data } = useGetMe()

  function onChangeHandler(e: any, newValues: string[]) {
    updateFormInput({ pool: newValues })
  }

  const input =
    Array.isArray(formInput.pool) &&
    formInput.pool.every((item) => typeof item === 'string')
      ? [...formInput.pool]
      : typeof formInput.pool === 'string'
        ? [formInput.pool]
        : []

  return (
    <MultiItemAutocomplete
      input={input}
      label="Pool(s) *"
      onChangeHandler={onChangeHandler}
      options={data?.activePools || []}
      hasError={missingFields?.includes('pool')}
      freeSolo={false}
      disabled={disabledFields?.includes('pool')}
    />
  )
}
