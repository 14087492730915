import { Popper } from '@mui/base'
import { format } from 'date-fns'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import { Params } from 'react-router-dom'
import { apiUpdateAction } from '~api/actions'
import { useAnimatedAlert } from '~components/molecules/animated-alert/animated-alert-provider'
import {
  CommentCell,
  CommentFieldWrapper,
  CommentInput,
  CommentText,
  ResponsibleWrapper,
  StyledDate,
  StyledName,
} from '~components/molecules/comment-field/comment-field.styles'
import CommentPopupContent from '~components/molecules/comment-field/comment-popup-content'
import { IComment } from '~pages/pages-behind-login/chartering/types/position-list-types'
import { Z_INDEX } from '~types/z-index'

export interface ApiConfig {
  endpoint: string
  params?: Record<string, any>
  urlParams?: Params
}

interface Props {
  apiConfig: ApiConfig
  canEdit?: boolean
  latestComment?: IComment
  width?: number
}

function CommentField({
  latestComment,
  apiConfig,
  width,
  canEdit = true,
}: Props) {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null)
  const [shouldHover, setShouldHover] = useState(false)
  const elementRef = useRef<HTMLTableCellElement | null>(null)
  const ref = useRef(null)
  const [isEditing, setIsEditing] = useState(false)
  const [newCommentText, setNewCommentText] = useState<string | undefined>()
  const [commentObject, setCommentObject] = useState<IComment | undefined>(
    latestComment,
  )
  const { setStatus } = useAnimatedAlert()

  const dateEdited = commentObject?.updatedAt
    ? new Date(commentObject?.updatedAt)
    : commentObject?.createdAt
      ? new Date(commentObject?.createdAt)
      : ''

  useEffect(() => {
    const element = elementRef.current

    // Only show popup on hover if the element is overflowing
    const checkOverflow = () => {
      if (element && element.scrollHeight > element.clientHeight) {
        setShouldHover(true)
      } else {
        setShouldHover(false)
      }
    }

    const resizeObserver = new ResizeObserver(checkOverflow)

    if (element) {
      resizeObserver.observe(element)
    }

    // Initial check
    checkOverflow()

    return () => {
      if (element) {
        resizeObserver.unobserve(element)
      }
    }
  }, [elementRef.current])

  const handlePersistComment = async () => {
    try {
      const response: Response = await apiUpdateAction(
        apiConfig.endpoint,
        {
          ...apiConfig.params,
          comment: newCommentText,
          ...(commentObject?.hash ? { hash: commentObject?.hash } : {}),
        },
        { ...apiConfig.urlParams },
      )

      if ('statusCode' in response) {
        setStatus(
          'Something went wrong, your comment was not added',
          undefined,
          'error',
        )
        setNewCommentText(commentObject?.comment)
        throw new Error()
      }

      if (response && typeof response === 'object') {
        if (newCommentText?.trim().length === 0) {
          setStatus('The comment was removed', 3000, 'info')
        } else {
          setStatus('Your comment was added', 3000, 'success')
        }
        setCommentObject((prev) => ({
          ...prev,
          ...(response as IComment),
        }))
        setNewCommentText((response as IComment).comment)
      }
    } catch (error) {
      setStatus(
        'Something went wrong, your comment was not added',
        3000,
        'error',
      )
      console.error(error)
    }
  }

  const handleMouseOver = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget)
  }

  const handleMouseLeave = () => {
    setAnchor(null)
  }

  const handleStartEditing = (event: any) => {
    if (!canEdit) return
    if (event.detail === 2) {
      setNewCommentText(commentObject?.comment)
      setIsEditing(true)
    }
    return
  }

  const handleStopEditing = () => {
    setIsEditing(false)
    if (newCommentText === commentObject?.comment) return
    handlePersistComment()
  }

  const handleEditComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewCommentText(e.target.value)
  }

  function getInitials(name?: string) {
    if (!name) return ''
    return name
      .split(/[\s-]/)
      .map((part) => part[0].toUpperCase())
      .join('')
  }

  const open = Boolean(anchor) && shouldHover && !isEditing
  const initials = getInitials(
    commentObject?.updatedResponsibleUser?.name ||
      commentObject?.responsibleUser?.name,
  )

  return (
    <CommentCell
      ref={ref}
      onMouseLeave={handleMouseLeave}
      onClick={handleStartEditing}
      $width={width}
    >
      <>
        <CommentFieldWrapper ref={elementRef} onMouseMove={handleMouseOver}>
          {isEditing ? (
            <CommentInput
              onChange={handleEditComment}
              rows={3}
              onBlur={handleStopEditing}
              autoFocus
              value={newCommentText}
            />
          ) : (
            <CommentText>{commentObject?.comment}</CommentText>
          )}
        </CommentFieldWrapper>
        {!isEditing &&
          commentObject?.comment?.trim().length !== 0 &&
          commentObject?.comment && (
            <ResponsibleWrapper>
              <StyledName>{initials}</StyledName>
              <StyledDate>
                {dateEdited ? format(dateEdited, 'dd-MM-yyyy') : ''}
              </StyledDate>
            </ResponsibleWrapper>
          )}
      </>
      <Popper
        open={open}
        anchorEl={anchor}
        placement="left-start"
        modifiers={[
          { name: 'flip', enabled: false },
          { name: 'offset', options: { offset: [-100, 0] } },
        ]}
        style={{ zIndex: Z_INDEX.Popup }}
      >
        <CommentPopupContent comment={commentObject?.comment} />
      </Popper>
    </CommentCell>
  )
}

export default CommentField
